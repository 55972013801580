
import { User } from '@/modules/auth/shared/models/user';
import DetailsComponent from '@/shared/components/layout/details/details-component';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { actionsTypes } from '@/modules/admin/shared/state';

@Component({})
export default class UserCard extends DetailsComponent<User> {
    @Prop(String) public declare id: string;
    public showAction = actionsTypes.SHOW_USER;
    public store = this.$store.state.adminState;

    public created() {
        this.fetchDetails();
    }

    get emailUrl(): string {
        return this.item && this.item.email ? 'mailto:' + String(this.item.email) : '';
    }
}
