var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-card',{staticClass:"fill-height",attrs:{"flat":""}},[_c('v-card-title',{staticClass:"d-flex justify-space-between"},[_c('span',[_vm._v(" Dane kontaktowe: "),(_vm.loading)?_c('v-progress-circular',{staticClass:"ml-2",attrs:{"indeterminate":"","color":"primary"}}):_vm._e()],1),(_vm.active)?_c('subform-action-buttons',{attrs:{"edit-mode":_vm.editMode},on:{"edit":function($event){return _vm.toggleEditMode()},"cancel":function($event){return _vm.abortEdition()},"save":function($event){return _vm.submit()}}},[_c('v-chip',{staticClass:"mx-1",attrs:{"pill":"","outlined":"","small":""},on:{"click":_vm.fillFromGus,"mouseenter":_vm.placeholdersFromGus,"mouseleave":_vm.clearPlaceholders}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-clipboard-text")]),_c('span',[_vm._v(" Uzupełnij według GUS ")])],1)],1):_vm._e()],1),(!_vm.loading && _vm.requestData !== null)?_c('v-card-text',{staticClass:"d-flex flex-column align-center",class:_vm.isMApp ? 'py-1 px-4' : 'py-4 px-8'},[_c('v-row',{staticClass:"py-0",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pr-1",attrs:{"cols":_vm.isMApp ? 12 : 6}},[_c('v-text-field',{style:(`${!_vm.editMode ? 'font-size: 26px; max-height: 62px;' : ' max-height: 62px;'}${
                        _vm.requestData.first_name !== '' && _vm.placeHolders.first_name === ''
                            ? 'text-decoration: line-through; color: var(--v-primary-base) !important;'
                            : ''
                    }`),attrs:{"disabled":!_vm.editMode,"flat":"","suffix":_vm.placeHolders.first_name,"label":"Imię","rules":[_vm.formRules.required]},model:{value:(_vm.requestData.first_name),callback:function ($$v) {_vm.$set(_vm.requestData, "first_name", $$v)},expression:"requestData.first_name"}})],1),_c('v-col',{attrs:{"cols":_vm.isMApp ? 12 : 6}},[_c('v-text-field',{style:(`${!_vm.editMode ? 'font-size: 28px; max-height: 62px;' : ' max-height: 62px;'}${
                        _vm.requestData.last_name !== '' && _vm.placeHolders.last_name === ''
                            ? 'text-decoration: line-through; color: var(--v-primary-base) !important;'
                            : ''
                    }`),attrs:{"disabled":!_vm.editMode,"flat":"","suffix":_vm.placeHolders.last_name,"label":"Nazwisko","rules":[_vm.formRules.required]},model:{value:(_vm.requestData.last_name),callback:function ($$v) {_vm.$set(_vm.requestData, "last_name", $$v)},expression:"requestData.last_name"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.mask.phone),expression:"mask.phone"}],style:(_vm.requestData.phone !== '' && _vm.placeHolders.phone === ''
                            ? 'text-decoration: line-through; color: var(--v-primary-base) !important;'
                            : ''),attrs:{"disabled":!_vm.editMode,"suffix":_vm.placeHolders.phone,"label":"Telefon","rules":[_vm.formRules.required]},model:{value:(_vm.requestData.phone),callback:function ($$v) {_vm.$set(_vm.requestData, "phone", $$v)},expression:"requestData.phone"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{style:(_vm.requestData.fax !== '' && _vm.placeHolders.fax === ''
                            ? 'text-decoration: line-through; color: var(--v-primary-base) !important;'
                            : ''),attrs:{"disabled":!_vm.editMode,"suffix":_vm.placeHolders.fax,"formRules":[_vm.formRules.faxEmpty],"label":"Faks"},model:{value:(_vm.requestData.fax),callback:function ($$v) {_vm.$set(_vm.requestData, "fax", $$v)},expression:"requestData.fax"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"mt-2",style:(_vm.requestData.email !== '' && _vm.placeHolders.email === ''
                            ? 'text-decoration: line-through; color: var(--v-primary-base) !important;'
                            : ''),attrs:{"disabled":!_vm.editMode,"suffix":_vm.placeHolders.email,"label":"Adres e-mail","rules":[_vm.formRules.required]},model:{value:(_vm.requestData.email),callback:function ($$v) {_vm.$set(_vm.requestData, "email", $$v)},expression:"requestData.email"}})],1),_c('v-col',{staticClass:"pr-1",attrs:{"cols":"12"}},[_c('v-text-field',{style:(_vm.requestData.website_address !== '' && _vm.placeHolders.website_address === ''
                            ? 'text-decoration: line-through; color: var(--v-primary-base) !important;'
                            : ''),attrs:{"disabled":!_vm.editMode,"suffix":_vm.placeHolders.website_address,"formRules":[_vm.formRules.websiteEmpty],"label":"Strona internetowa"},model:{value:(_vm.requestData.website_address),callback:function ($$v) {_vm.$set(_vm.requestData, "website_address", $$v)},expression:"requestData.website_address"}})],1),_c('v-col',{staticClass:"pr-1",attrs:{"cols":_vm.isMApp ? 12 : 6}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('##-###'),expression:"'##-###'"}],style:(`${!_vm.editMode ? 'font-size: 28px; max-height: 62px;' : ' max-height: 62px;'}${
                        _vm.requestData.zip !== '' && _vm.placeHolders.zip === ''
                            ? 'text-decoration: line-through; color: var(--v-primary-base) !important;'
                            : ''
                    }`),attrs:{"disabled":!_vm.editMode,"flat":"","suffix":_vm.applyZipCodeMask(_vm.placeHolders.zip),"label":"Kod pocztowy","rules":[_vm.formRules.required, _vm.formRules.postCode]},model:{value:(_vm.requestData.zip),callback:function ($$v) {_vm.$set(_vm.requestData, "zip", $$v)},expression:"requestData.zip"}})],1),_c('v-col',{attrs:{"cols":_vm.isMApp ? 12 : 6}},[_c('v-text-field',{style:(`${!_vm.editMode ? 'font-size: 28px; max-height: 62px;' : ' max-height: 62px;'}`),attrs:{"disabled":!_vm.editMode,"flat":"","suffix":_vm.placeHolders.city,"label":"Miasto","rules":[_vm.formRules.required]},model:{value:(_vm.requestData.city),callback:function ($$v) {_vm.$set(_vm.requestData, "city", $$v)},expression:"requestData.city"}})],1),_c('v-col',{staticClass:"d-flex pb-6",attrs:{"cols":"12"}},[_c('div',{staticClass:"fill-height"}),_c('v-text-field',{staticClass:"pt-0 mt-2",style:(`
                        ${
                            _vm.requestData.address !== '' && _vm.placeHolders.address === ''
                                ? 'text-decoration: line-through; color: var(--v-primary-base) !important;'
                                : ''
                        }${!_vm.editMode ? 'font-size: 28px; max-height: 62px;' : ' max-height: 62px;'}`),attrs:{"disabled":!_vm.editMode,"suffix":_vm.placeHolders.address,"label":"Adres","rules":[_vm.formRules.required]},model:{value:(_vm.requestData.address),callback:function ($$v) {_vm.$set(_vm.requestData, "address", $$v)},expression:"requestData.address"}})],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }