
import { getOrderStatus } from '@/modules/admin/shared/services/orders';
import { Component, Vue } from 'vue-property-decorator';
import { mdiBank } from '@mdi/js';
import { PaymentStatus } from '@/modules/admin/shared/models/status';

@Component({
    props: {
        staticStatus: Number,
    },
})
export default class PaymentReturn extends Vue {
    public bank = mdiBank;
    public statusID = 0;
    public checker: any;

    get status() {
        if (this.statusID < 3) {
            return 'loading';
        }
        if (this.statusID === 3 || this.statusID === 5) {
            return 'error';
        }
        if (this.statusID > 2) {
            return 'success';
        }
    }

    get message() {
        if (this.statusID === 1) {
            return 'Oczekujące na płatność';
        }
        if (this.statusID === 2) {
            return 'W trakcie realizacji';
        }
        if (this.statusID === 3) {
            return 'Zrealizowane';
        }
        if (this.statusID === 4) {
            return 'Zafakturowane';
        }
        if (this.statusID === 5) {
            return 'Anulowane';
        }
        if (this.statusID === 6) {
            return 'Zwrócone';
        }
        if (this.statusID === 7) {
            return 'Nieudane';
        }
        return 'Ładowanie';
    }

    public mounted() {
        this.checkStatus();
        // this.checker = setInterval(() => {
        //     this.checkStatus();
        // }, 2000);
    }

    public destroyed() {
        clearInterval(this.checker);
    }

    public checkStatus() {
        if (this.$route.query.orderId) {
            getOrderStatus(this.$route.query.orderId).then((res) => {
                this.statusID = res.data.id;
            });
        }
    }

    get iconToShow() {
        if (this.status === 'success') {
            return 'mdi-check';
        } else if (this.status === 'error') {
            return 'mdi-close';
        } else {
            return '';
        }
    }


}
