
import FormComponent from '@/shared/components/layout/form/form-component';
import {ModuleState} from '@/shared/state/template/module-state';
import {actionsTypes, ModuleState as AdminState} from '../../shared/state';
import {UserInviteDataRequest} from '@/modules/admin/shared/requests/user-invite-data-request';
import {UserInvite} from '@/modules/admin/shared/models/user-invite';
import {userInvitesModule} from '@/modules/admin/shared/state/invites';
import {Component, Prop, Watch} from 'vue-property-decorator';
import FormActions from '@/shared/components/layout/form/form-actions.vue';
import {logger} from '@/shared/services';
import { resourceLimits } from 'worker_threads';
import ActionConfirmDialog from '@/shared/components/dialogs/action-confirm-dialog.vue';

@Component({
    components: {
        FormActions,
        ActionConfirmDialog,
    }
})
export default class InviteForm extends FormComponent<UserInviteDataRequest> {
    @Prop(Number) public MaxUsers!: number;
    @Prop(Number) public ActiveUsers!: number;
    @Prop(Number) public PendingInvites!: number;
    public store: ModuleState<UserInvite, UserInviteDataRequest> = this.$store.state.adminState.userInvitesState;
    public actionsTypes = userInvitesModule.actionsTypes;
    public storeAction: string = this.actionsTypes.STORE_ITEM;
    public updateAction: string = this.actionsTypes.UPDATE_ITEM;
    public adminState: AdminState = this.$store.state.adminState;
    public emailInput = '';
    public tooMuchDialog = false;

    get formTitle(): string {
        return 'Zaproś użytkowników';
    }

    get roles() {
        return this.adminState.roles;
    }

    get rolesLoading() {
        return this.adminState.loadingList;
    }

    public created() {
        this.$store.dispatch(actionsTypes.FETCH_ROLE_DATA, {simple: true});
    }

    public mounted() {
        this.form = this.$refs.inviteUsersForm;
    }

    public async submitForm(e: any, next?: boolean) {
        if (!this.form || this.busy) {
            return;
        }

        await this.form.validate();

        if (this.valid) {
            const action = this.edit ? this.updateAction : this.storeAction;
            this.busy = true;
            const requestParams = this.requestParams;

            this.$store.dispatch(action, requestParams)
                .then((response) => {
                    if (this._.isEqual(requestParams.emails, response.data.map(({email}: UserInvite) => email))) {
                        this.valid = true;
                        this.form.reset();
                        this.$emit('saved', { data: response.data, next });

                        if (next) {
                            this.$emit('next', response.data);
                            this.initFocus();
                            this.formNextCallback(response.data);
                        } else {
                            this.formCallback(response.data);
                        }
                    }
                })
                .catch((err) => logger.error(err))
                .then(() => {
                    this.busy = false;
                });
        }
    }

    get userAmount() {
        if (this.MaxUsers === 0) {
            return '';
        }
        const usersLeft = this.MaxUsers - this.ActiveUsers;
        const currentInvites = this.PendingInvites +
            (this.itemData.emails ? this.itemData.emails.length : 0);
        if ( usersLeft - currentInvites < 0 ) {
            let res = 'Możesz dodać jedynie ' + usersLeft
                + (usersLeft === 1 ? ' użytkownika' : ' użytkowników');
            res += this.PendingInvites === 0 ? '' : ', a ' + this.PendingInvites +
                (this.PendingInvites === 1 ? ' zaproszenie' : 'zaproszeń')
                + ' już oczekuje na akceptację. Usuń aktywne zaproszenie, aby dodać kolejne';
            return res;
        }
        return '';
    }

    @Watch('emailInput')
    public  onEmailInputChanged(val: string) {
        if ( val && val.replace(/ /g, '') !== '' && val[val.length - 1] === ' ') {
            this.itemData.emails.push(val.replace(/ /g, ''));
            this.emailInput = '';
        }
    }
}

