
import { httpClient } from '@/shared/services';
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
@Component({})
export default class GusDataByNip extends Vue {
    @Prop({ type: Object, default: {} }) public textFieldProps!: any;
    @Prop([String, Object]) public value!: any;
    @Prop(Array) public propertiesToReturn!: string[];

    public search: string = '';
    public preventRequest = false;
    public showCheckButton = false;
    public errorMessages = [];
    public loading = false;

    public sendSearchRequest(search: string) {
        this.loading = true;
        httpClient
            .get(`api/v1/contractors/gus/search-by-nip/${search}`)
            .then((response: any) => {
                if (response.data.success) {
                    this.showCheckButton = true;
                    this.$emit('search-result', response.data.data);
                } else {
                    this.errorMessages.push(response.data.error as never);
                }
            })
            .catch((err) => {
                if (err.status === 404) {
                    this.errorMessages.push('Nie znaleziono danych w GUS' as never);
                }
                if (err.status === 422) {
                    this.errorMessages.push('Niepoprawny numer NIP' as never);
                }
            })
            .finally(() => {
                this.loading = false;
            });
    }

    public accept() {
        this.showCheckButton = false;
        this.$emit('accept');
    }

    @Watch('search', { immediate: true })
    public onSearchChange(val: string) {
        this.errorMessages = [];
        this.$emit('search-result');
        if (this.preventRequest) {
            this.preventRequest = false;
            return;
        }
        this.showCheckButton = false;
        if (this.search && this.search.length === 10) {
            this.sendSearchRequest(val);
        }
    }

    @Watch('value')
    public onValueChange() {
        if (!this.value || this.value.length === 0) {
            if (!this.search) {
                this.search = '';
            }
            return;
        }
        if (typeof this.value === 'object') {
            this.preventRequest = true;
            this.$emit('result', this.value);
        } else if (typeof this.value === 'string') {
            this.preventRequest = true;
            this.search = this.value;
        } else {
            this.search = '';
            this.$emit('input', '');
        }
    }
}
