
import Vue from 'vue';
import Component from 'vue-class-component';
import { Client } from '../../shared/models/client';
import { User } from '@/modules/auth/shared/models/user';
import { clientModule } from '../../shared/state/module';
import CompanyData from './company-data.vue';
import ContactData from './contact-data.vue';
import InvoiceData from './invoice-data.vue';
import DeleteProfile from './delete-profile.vue';
@Component({
    components: { CompanyData, ContactData, InvoiceData, DeleteProfile },
})
export default class DeveloperProfile extends Vue {

    public resizeTimeout: any = null;
    public currentTab = 0;
    public editMode = false;
    public store = this.$store.state.clientState;
    public actionTypes = clientModule.actionsTypes;
    public loading = true;
    public invoiceData: any = null;
    public nipSearchResult = {};
    public isMApp = (this.$vuetify.breakpoint.name === 'xs' || this.$vuetify.breakpoint.name === 'sm');

    get user(): User {
        return this.$store.state.authState.user;
    }

    get isAdmin() {
        return this.user ? this.$store.state.authState.user.is_admin : false;
    }

    get editedItem() {
        return this.store.editedItem;
    }

    set editedItem(val: Client) {
        this.store.editedItem = val;
    }

    get itemData() {
        return this.store.current;
    }
    set itemData(val: Client) {
        this.store.editedItem = val;
    }

    get isLoggedIn() {
        return this.$store.getters.isLoggedIn;
    }

    public mounted() {
        window.addEventListener('resize', this.resizeHandler);
        if ( this.isLoggedIn ) {
            this.fetchData();
        }
    }

    public destroyed() {
        window.removeEventListener('resize', this.resizeHandler);
    }

    public scrollTo(refName: string, toggle?: () => {}) {
        const ref = this.$refs[refName];
        if (!ref) {
            return;
        }
        this.$nextTick(() => {
            if (this.$refs.verticalScroll) {
                const refElement = (ref as any).$el;
                (this.$refs.verticalScroll as any).scrollTo({
                    left:
                        refElement.offsetLeft -
                        (this.$vuetify.breakpoint.mdAndUp ? refElement.getBoundingClientRect().width / 2 : 12),
                    behavior: 'smooth',
                });
            }
        });
        if (!!toggle) {
            toggle();
        }
    }

    public fetchData() {
        this.loading = true;
        this.$store.dispatch(this.actionTypes.GET_CLIENT).then((res: any) => {
            this.editedItem = this._.cloneDeep(res.data);
            this.invoiceData = this._.cloneDeep(this.itemData);
            if (this.$refs.invoiceData) {
                (this.$refs.invoiceData as any).$forceUpdate();
            }

            this.$nextTick(() => {
                this.loading = false;
            });
        });
    }

    public saveChanges(changes: Client) {
        this.loading = true;
        if (changes?.logo === null) {
            changes.logo = '';
        }

        const formData = new FormData();
        for (const k of Object.keys(changes)) {
            // need to manualy assign params to nested object when using formData otherwise it sends: "[object Object]"
            if (k === 'billing_address' && (changes as any)[k] === null) {
                (changes as any)[k] = '';
            } else if (k === 'billing_address' && (changes as any)[k]) {
                for (const billingKey of Object.keys((changes as any)[k])) {
                    formData.append(`billing_address[${billingKey}]`, (changes as any)[k][billingKey]);
                }
                continue;
            }

            formData.append(k, (changes as any)[k]);
        }

        this.$store.dispatch(this.actionTypes.UPDATE_CLIENT, formData)
            .then((res) => {
                this.editedItem = this._.cloneDeep(res.data.data);
                this.itemData = this._.cloneDeep(res.data.data);
                this.invoiceData = this._.cloneDeep(this.itemData);
                if (this.$refs.invoiceData) {
                    (this.$refs.invoiceData as any).$forceUpdate();
                }
            }).finally(() => {
                this.fetchData();
            });
    }

    public resizeHandler() {
        if (this.resizeTimeout) {
            clearTimeout(this.resizeTimeout);
        }
        let elementToScroll!: string;
        switch (this.currentTab) {
            case 1:
                elementToScroll = 'contactData';
                break;
            case 2:
                elementToScroll = 'invoiceData';
                break;
            case 3:
                elementToScroll = 'deleteData';
                break;
            default:
                elementToScroll = 'companyData';
                break;
        }
        this.resizeTimeout = setTimeout(() => {
            this.$nextTick(() => {
                this.scrollTo(elementToScroll);
            });
        }, 200);
    }
}
