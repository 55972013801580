

import Vue from 'vue';
import {Component, Inject} from 'vue-property-decorator';
import SubscriptionCard from '@/modules/admin/components/subscriptions/subscription-card.vue';
import {getSubscriptions} from '@/modules/admin/shared/services/subscriptions';
import {
    getActiveSubscriptions,
    getDeActiveSubscriptions,
    getTrialSubscription
} from '@/modules/admin/shared/helpers/subscriptions';
import {SubscriptionsMeta} from '@/modules/admin/shared/models/subscriptions/subscriptions-meta';

@Component({
    components: {
        SubscriptionCard,
    },
})
export default class SubscriptionsList extends Vue {
    @Inject() public readonly permissionCheck!: (...permissions: string[]) => boolean;
    public activeSubscriptions: any = [];
    public deActiveSubscriptions: any = [];
    public meta?: SubscriptionsMeta | null = null;
    public loading = false;
    public showHistory = false;

    public mounted() {
        this.loading = true;
        getSubscriptions().then((response) => {
            if (response.data) {
                this.activeSubscriptions = getActiveSubscriptions(response.data);
                this.deActiveSubscriptions = this._.orderBy(getDeActiveSubscriptions(response.data), ['created_at'], ['desc']);
                this.meta = response.meta;

                this.loading = false;

                if (this.activeSubscriptions.length === 0) {
                    this.activeSubscriptions = [
                        getTrialSubscription(this.user),
                    ];
                }
            }
        });
    }

    get user() {
        return this.$store.state.authState.user;
    }

    public isPermitted(actionName: string) {
        return this.permissionCheck(`admin.subscriptions.${actionName}`);
    }

}
