
import ProfileSubform from './profile-subform';
import ConfirmWithDataDialog from '@/shared/components/dialogs/confirm-with-data-dialog.vue';
import { Prop, Component, Mixins} from 'vue-property-decorator';
import { httpClient, logger } from '@/shared/services';
import { actionsTypes } from '@/modules/auth/shared/state';
import { callSuper } from '@/shared/helpers';

@Component({
    components: {
        ConfirmWithDataDialog,
    }
})
export default class DeleteProfile extends Mixins(ProfileSubform) {
    public formDialog = false;
    public confirmDelete = false;
    public confirmedByPassword = true;
    public checkLoad = false;
    @Prop(String) private hostname!: string;

    get devHost() {
        return this.hostname ? this.hostname.slice(0, this.hostname.indexOf('.')) : '';
    }

    public deleteProfile() {
        this.checkLoad = true;
        httpClient
            .delete('api/v1/admin/client')
            .then((res) => {
                this.$store.dispatch(actionsTypes.LOGOUT_USER)
                .then(() => {
                    this.$router.push({name : 'login'});
                })
                .catch((err) => {
                    this.checkLoad = false;
                    this.formDialog = false;
                    logger.error(err); });
            })
            .catch((err) => {
                this.checkLoad = false;
                logger.error(err);
            });
    }

    public onCancel() {
        this.formDialog = false;
        this.checkLoad = false;
    }

}

