import {parseMoment} from '@/shared/helpers';
import moment from 'moment';
import {User} from '@/modules/auth/shared/models/user';
import {Subscription} from '@/modules/admin/shared/models/subscriptions/subscription';

export const getTrialSubscription = (user: User) => {
    return {
        plan: {
            name: 'Darmowa subskrypcja',
        },
        ends_at: user.trial_ends_at,
        active: !moment().isSameOrAfter(parseMoment(user.trial_ends_at)),
        order_id: null,
        order_status_id: null,
    };
};

export const getActiveSubscriptions = (subscriptions: Subscription[]) => subscriptions.filter((subscription: any) =>
    subscription.active === true && !moment().isSameOrAfter(parseMoment(subscription.ends_at))
);

export const getDeActiveSubscriptions = (subscriptions: Subscription[]) => subscriptions.filter((subscription: any) =>
    subscription.active !== true
);
