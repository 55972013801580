
import ProfileSubform from './profile-subform';
import { ClientDataRequest } from '@/modules/client/shared/requests/client-data-request';
// import  from 'vue-class-component';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import SubformActionButtons from './subform-action-buttons.vue';
import GusDataByNip from '@/shared/components/elements/gus-data-by-nip.vue';
import { httpClient } from '@/shared/services';
import { callSuper, dataURItoBlob } from '@/shared/helpers';
import ImageInput from '../image-input.vue';

@Component({
    components: {
        SubformActionButtons,
        GusDataByNip,
        ImageInput,
    },
})
export default class CompanyData extends Mixins(ProfileSubform) {

    @Prop(Object) public nipSearchResult: any;

    public previewImage: any = null;
    public itemDataKeysToChange = [
        'company_name',
        'nip',
        'description',
        'regon',
        'krs',
        'logo',
        'bank_account_number',
        'bdo',
    ];
    public dataURItoBlob = dataURItoBlob;
    public logoLoading = false;
    public logo: File | null = null;
    public cachedLogo: File | null = null;
    public ignoreLogoChange = false;
    public isMApp = (this.$vuetify.breakpoint.name === 'xs' || this.$vuetify.breakpoint.name === 'sm');

    public mounted() {
        this.fetchLogo();
    }

    public abortEdition() {
        this.placeHolders = {} as ClientDataRequest;
        callSuper(this, 'abortEdition');
        this.fetchLogo();
    }
    public submit(data?: any) {
        this.cachedLogo = null;
        this.placeHolders = {} as ClientDataRequest;

        if (data) {
            callSuper(this, 'submit', data);
        } else {
            callSuper(this, 'submit');
        }
        this.fetchLogo();
    }

    public onNipResult(result: any) {
        if (!result) {
            this.placeHolders = {} as ClientDataRequest;
            this.$forceUpdate();
            return;
        }

        // @ts-ignore-next-line
        for (const k of Object.keys(result)) {
            if (k === 'name') {
                this.$set(this.placeHolders as any, 'company_name', result[k]);
                continue;
            }
            if (this.itemDataKeysToChange.includes(k)) {
                this.$set(this.placeHolders as any, k, result[k]);
            }
        }

        this.$forceUpdate();
    }

    public fetchLogo() {
        this.logoLoading = true;
        if (this.cachedLogo) {
            this.logo = this.cachedLogo;
            this.logoLoading = false;
            return;
        }
        httpClient
            .get('/api/v1/admin/logo', { responseType: 'blob' })
            .then((res: any) => {
                this.logo = new File([res.data], 'Przesłane logo');
                this.cachedLogo = this.logo;
                this.logoLoading = false;
                this.$watch('logo', this.onLogoChange);
            })
            .catch((err: any) => {
                this.logoLoading = false;
                this.$watch('logo', this.onLogoChange);
            });
    }

    public onLogoChange(val: any) {
        if ((!val && val !== null) || !this.requestData) {
            return;
        }
        this.requestData.logo = val;
    }
}
