
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {parsePrice} from '@/shared/helpers';
import {OrderItemResponse} from '@/modules/admin/components/orders/module';
import {getOrder} from '@/modules/admin/shared/services';
import {ModuleShowPayload} from '@/shared/state/template/module-payloads';
import {ErrorPayload} from '@/shared/payloads/error-payload';
import {appDebug, environment} from '@/environment/environment';
import {Environment, Snackbar} from '@/shared/types';
import {httpClient} from '@/shared/services';
import {FormRules} from '@/shared/validation/form-rules';
import {User} from '@/modules/auth/shared/models/user';

@Component
export default class PaymentData extends Vue {
    public order: OrderItemResponse | null = null;
    public parsePrice = parsePrice;
    public email: string = '';
    public loading = false;
    public emailDialog = false;
    public formRules = new FormRules();
    public error = false;
    @Prop(String) private id!: string;

    get user(): User {
        return this.$store.state.authState.user;
    }

    // public created() {
    //     this.email = this.$store.state.authState.user.email;
    // }

    public sendTransferData() {
         httpClient.post('/api/v1/subscriptions/send-transfer-data', {
            order_id: this.id,
            email: this.email,
        }).then((response) => {
            if (response.status === 201) {
                this.$store.commit('SHOW_SNACKBAR', {
                    type: 'info',
                    text: 'E-mail został wysłany',
                } as Snackbar);
                this.emailDialog = false;
            }
        });
    }

    public mounted() {
        this.loading = true;
        getOrder(Number(this.id))
            .then((response: ModuleShowPayload<OrderItemResponse>) => {
                if (response.data) {
                    this.loading = false;
                    this.order = response.data;
                    if (this.order.order_status_id !== 1) {
                        this.$router.push({name: 'subscriptions-list'}).then(() => {
                            if (appDebug || environment === Environment.Development) {
                                this.error = true;
                                this.$store.commit(
                                    'SHOW_SNACKBAR', {
                                        type: 'error',
                                        text: 'Zamówienie zostało już opłacone',
                                    } as Snackbar
                                );
                            }
                        });
                    }
                }
            })
            .catch((e: ErrorPayload) => {
                this.$router.push({name: 'subscriptions-list'}).then(() => {
                    if (appDebug || environment === Environment.Development) {
                        this.error = true;
                        this.$store.commit(
                            'SHOW_SNACKBAR', {
                                type: 'error',
                                text: 'Zamówienie nie istnieje',
                            } as Snackbar
                        );
                    }
                });
            });
    }

    // public sendEmail() {
    //     httpClient.post('/api/v1/payments/methods/transfer/send-email', {
    //         order_id: this.id,
    //         email: this.email,
    //     }).then((response) => {
    //         if (response.status === 201) {
    //             this.$store.commit('SHOW_SNACKBAR', {
    //                 type: 'info',
    //                 text: 'E-mail został wysłany',
    //             } as Snackbar);
    //         }
    //     });
    // }

    @Watch('user')
    public onUserChange(user: User) {
        if (user && user.email) {
            this.email = user.email;
        }
    }

}
