
import { Component, Prop } from 'vue-property-decorator';
import DetailsComponent from '@/shared/components/layout/details/details-component';
import { User } from '@/modules/admin/shared/models/user';
import { actionsTypes } from '@/modules/admin/shared/state';
import { mdiEmail } from '@mdi/js';
import { httpClient } from '@/shared/services';
import PermissionsTable from '@/modules/admin/components/permissions/permissions-table.vue';
import { Permission } from '@/modules/admin/shared/models/premission';
import { Role } from '@/modules/admin/shared/models/role';
import TopToolbar from '@/shared/components/layout/details/top-toolbar.vue';
import DatePicker from '@/shared/components/elements/date-picker.vue';
import PreventUnload from '@/shared/PreventUnload.vue';
import { FormRules } from '@/shared/validation/form-rules';
import { parseDate } from '@/shared/helpers';
import TooltipBtn from '@/shared/components/elements/tooltip-btn.vue';

@Component({
    components: {
        PermissionsTable,
        TopToolbar,
        DatePicker,
        PreventUnload,
        TooltipBtn,
    },
})
export default class UserDetails extends DetailsComponent<Role> {
    @Prop(String) public declare id: string;
    public showAction = actionsTypes.SHOW_ROLE;
    public store = this.$store.state.adminState;
    public permissions: Permission[] = [];
    public editedItem: Role | null = null;
    public formRules = new FormRules();
    public parseDate = parseDate;
    public isValid = false;

    get createMode() {
        return this.id === 'new';
    }

    get itemChanged() {
        return !this._.isEqualWith(this.item, this.editedItem);
    }

    set loading(val: boolean) {
        this.store.loading = val;
    }
    get loading() {
        return this.store.loading;
    }

    get isNotAnyPermissionSelected() {
        return (
            !this.editedItem ||
            !this.editedItem.permission_ids ||
            (!!this.editedItem &&
                !!this.editedItem.permission_ids &&
                (!this.editedItem.permission_ids || this.editedItem.permission_ids.length === 0))
        );
    }

    get tooltipText() {
        if (!this.isPermitted(this.createMode ? 'create' : 'edit')) {
            return 'Brak uprawnień';
        } else {
            return this.isNotAnyPermissionSelected || (this.editedItem?.name && this.editedItem?.name.length === 0)
                ? 'Brak nazwy lub zaznaczonych uprawnień'
                : 'Zapisz';
        }
    }

    public created() {
        this.loading = true;

        if (!this.createMode) {
            this.fetchDetails();
        } else {
            this.setupEditedItem();
        }
        this.fetchPermissions();
    }

    public fetchCallback() {
        this.setupEditedItem();
    }

    public saveChanges() {
        this.loading = true;
        if (this.createMode) {
            httpClient
                .post(`api/v1/users/roles`, this.editedItem)
                .then((res) => {
                    if (res.status === 202) {
                        this.$store.commit('SHOW_SNACKBAR', {
                            type: 'success',
                            text: 'Dodano nową rolę',
                        });
                    }
                    this.$router.replace({ name: 'role-details', params: { id: res.data.data.id } });
                })
                .catch((err) => {
                    this.$store.commit('SHOW_SNACKBAR', {
                        type: 'error',
                        text: err.errors ? err.errors.name[0] : 'Coś poszło nie tak',
                    });
                })
                .finally(() => {
                    this.loading = false;
                });
        } else {
            httpClient
                .put(`api/v1/users/roles/${this.id}`, this.editedItem)
                .then((res) => {
                    if (res.status === 202) {
                        this.$store.commit('SHOW_SNACKBAR', {
                            type: 'success',
                            text: 'Zmiany zostały zapisane',
                        });
                    }
                    this.fetchDetails();
                })
                .catch((err) => {
                    this.$store.commit('SHOW_SNACKBAR', {
                        type: 'error',
                        text: err.errors ? err.errors.name[0] : 'Coś poszło nie tak',
                    });
                })
                .finally(() => {
                    this.loading = false;
                });
        }
    }

    public deleteItem() {
        httpClient.delete(`api/v1/users/roles/${this.id}`).then((res) => {
            if (res.status === 204) {
                this.$store.commit('SHOW_SNACKBAR', {
                    type: 'success',
                    text: 'Pomyślnie usunięto element',
                });
            } else {
                this.$store.commit('SHOW_SNACKBAR', {
                    type: 'success',
                    text: 'Coś poszło nie tak',
                });
            }
            this.$router.replace('/admin/users/user-roles');
        });
    }

    public isPermitted(actionName: string) {
        return this.permissionCheck(`admin.roles.${actionName}`);
    }

    private fetchPermissions() {
        httpClient.get('api/v1/users/permissions/list').then(({ data }) => {
            this.permissions = data.data;
            this.loading = false;
        });
    }

    private setupEditedItem() {
        if (!this.createMode) {
            this.editedItem = this._.cloneDeep(this.item);
        } else {
            this.editedItem = { guard_name: 'user', created_at: new Date(), updated_at: new Date() } as Role;
        }
    }
}
