
import {Component, Prop, Watch} from 'vue-property-decorator';
import DetailsComponent from '@/shared/components/layout/details/details-component';
import {User} from '@/modules/admin/shared/models/user';
import {actionsTypes, mutationTypes} from '@/modules/admin/shared/state';
import { actionsTypes as settingsActions } from '@/modules/settings/shared/state';
import {mdiEmail} from '@mdi/js';
import {httpClient} from '@/shared/services';
import TopToolbar from '@/shared/components/layout/details/top-toolbar.vue';
import {Role} from '../../shared/models/role';
import {Permission} from '../../shared/models/premission';
import {FormRules} from '@/shared/validation/form-rules';
import {dataURItoBlob, parseDate} from '@/shared/helpers';
import MediaFileInput from '@/modules/media/components/media-file-input.vue';
import PermissionsTable from '@/modules/admin/components/permissions/permissions-table.vue';
import { UserDataRequest } from '../../shared/requests/user-data-request';
import router from '@/router';
import TooltipBtn from '@/shared/components/elements/tooltip-btn.vue';
import ImageInput from '@/shared/components/elements/image-input.vue';
import {AxiosRequestConfig} from 'axios';
import {Snackbar} from '@/shared/types';
import ErrorBoundary from '@/shared/components/error-boundary/error-boundary.vue';
import { SettingsDataRequest } from '@/modules/settings/shared/requests/settings-data-request';

@Component({
    components: {
        ErrorBoundary,
        TopToolbar,
        ImageInput,
        MediaFileInput,
        PermissionsTable,
        TooltipBtn,
    },
})
export default class UserDetails extends DetailsComponent<User> {
    @Prop(String) public declare id: string;
    // Do podpięcia funkcji z DAPK-1 jak będzie zaakceptowane
    public isMApp = (this.$vuetify.breakpoint.name === 'sm' || this.$vuetify.breakpoint.name === 'xs');
    public showAction = actionsTypes.SHOW_USER;
    public store = this.$store.state.adminState;
    public editedItem: User | null = {} as User;
    public permissions: Permission[] = [];
    public isValid = false;
    public formRules = new FormRules();
    public parseDate = parseDate;
    public form: any = null;
    public updateAction = actionsTypes.UPDATE_USER;
    public storeAction = actionsTypes.STORE_USER;
    public showChangePassword: boolean = false;
    public checkedPermissions: number[] = [];
    public rolesLoading = false;
    public loadingAvatar: boolean = false;
    public cropDialog: boolean = false;
    public avatarPhoto: string | null = null;
    public newAvatar: string | null = null;
    public icons: object = {
        mdiEmail,
    };

    get user() {
        return this.$store.state.authState.user;
    }

    get itemChanged() {
        return !this._.isEqualWith(this.item, this.editedItem) || (this.avatarPhoto !== this.newAvatar);
    }

    get createMode() {
        return this.id === 'new';
    }

    get roles() {
        return this.store.roles;
    }

    get emailUrl(): string {
        return this.item && this.item.email ? 'mailto:' + String(this.item.email) : '';
    }

    public imageCropped(cropped: string|null) {
        this.newAvatar = cropped;
        this.cropDialog = false;
    }

    public closeCropDialog() {
        this.cropDialog = false;
    }


    get passwordConfirmation() {
        return () =>
            !(this.editedItem && this.editedItem.password && this.editedItem.password.length > 0) ||
            this.editedItem.password === this.editedItem.password_confirmation ||
            'Hasła się nie zgadzają';
    }

    get errorMessages() {
        return this.store ? this.store.errorMessages : {};
    }

    get haveWritePermission() {
        return this.isPermitted(this.createMode ? 'create' : 'edit');
    }

    public created() {
        this.$store.dispatch(actionsTypes.FETCH_ROLE_DATA, {simple: true}).then(() => {
            this.fetchPermissions();
            if (!this.createMode) {
                this.fetchDetails();
            } else {
                this.setupEditedItem();
            }
        });
    }

    public fetchCallback() {
        this.setupEditedItem();
    }

    public changePassword() {
        if (!this.editedItem) {
            return;
        }
        this.showChangePassword = false;
        this.saveChanges({
            password: this.editedItem.password,
            password_confirmation: this.editedItem.password_confirmation,
        });
        this.clearError('password');
    }

    public clearError(name: string) {
        if (this.errorMessages[name]) {
            delete this.errorMessages[name];
            if (this.errorMessages.length > 0) {
                return;
            }
            return (this.store.error = '');
        }
    }

    public async saveChanges(item?: any): Promise<void> {
        if (!item) {
            item = this.editedItem;
        }
        this.form = this.$refs.form;

        if (!this.editedItem) {
            return;
        }

        const formData = new FormData();
        const keys = Object.keys(this.editedItem);

        keys.forEach((key) => {
            if (!['role_ids', 'avatar_photo', 'is_admin'].includes(key) && item[key]) {
                formData.append(key, item[key]);
            }
        });

        formData.append(
            'avatar_photo',
            this.newAvatar ? dataURItoBlob(this.newAvatar, 'avatar.png') : '',
        );

        formData.append('is_admin', String(Number(this.editedItem.is_admin)));


        if (item.role_ids) {
            for (const role of item.role_ids) {
                formData.append('role_ids[]', role);
            }
        }

        await this.form.validate();

        if (this.isValid) {
            const config: AxiosRequestConfig = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
            };

            if (!this.createMode) {
                config.params = {
                    _method: 'PUT',
                };
            }

            this.$store.commit(mutationTypes.USER_GET_REQUEST);


            httpClient.post(
                `/api/v1/users${!this.createMode ? '/' + this.id : ''}`,
                formData,
                config,
            ).then((response) => {
                if (
                    (response && response.data && response.data.id) ||
                    (response && response.hasOwnProperty('status') && response.status)
                ) {
                    const snackbar = {
                        type: 'success',
                        text: `${this.createMode ? 'Utworzono' : 'Zaktualizowano'} użytkownika`,
                    } as Snackbar;
                    this.$store.commit('SHOW_SNACKBAR', snackbar);
                    this.isValid = true;
                    this.$store.commit(mutationTypes.USER_GET_SUCCESS, response.data);
                    this.avatarPhoto = this.newAvatar;
                    this.setupEditedItem();
                    this.$router.push({ name: 'users-list' });
                }
            });

        }
    }

    public isPermitted(actionName: string) {
        return this.permissionCheck(`admin.users.${actionName}`);
    }

    private fetchPermissions() {
        httpClient.get('api/v1/users/permissions/list').then(({data}) => {
            this.permissions = data.data;
        });
    }

    private setupEditedItem() {
        if (!this.createMode) {
            this.editedItem = this._.cloneDeep(this.item);
        } else {
            this.editedItem = new UserDataRequest();
        }

        if (this.editedItem && this.editedItem.avatar_photo && !this.avatarPhoto) {
            const hostname = `${window.location.protocol}//${window.location.hostname}`;
            const url = this.editedItem.avatar_photo.replace(hostname, '');

            this.loadingAvatar = true;
            this.toDataURL(url, (dataUrl) => {
                if (dataUrl && typeof dataUrl === 'string') {
                    this.loadingAvatar = false;
                    this.avatarPhoto = dataUrl;
                    this.newAvatar = dataUrl;
                }
            });
        }

        this.$nextTick(() => {
            this.$watch('editedItem.role_ids', this.onRolesChange, {immediate: true});
            this.$watch('editedItem.is_admin', this.onIsAdminChange, {immediate: true});
        });
    }


    private toDataURL(url: string, callback: (dataUrl: string | ArrayBuffer | null) => void) {
        httpClient.get(url, {
            responseType: 'arraybuffer',
        }).then((response) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                callback(reader.result);
            };
            reader.readAsDataURL(new Blob([response.data]));
        });
    }

    private onRolesChange(val: number[]) {
        if (!val || val.length === 0) {
            return;
        }
        if (this.editedItem && !this.editedItem.is_admin) {
            const permissions: number[] = [];
            val.forEach((roleId) => {
                const role = this.roles.find((el: Role) => el.id === roleId);
                permissions.push(...[...role.permission_ids]);
            });
            this.checkedPermissions = this._.uniq(permissions);
        }
    }

    private onIsAdminChange(val: boolean) {
        if (!this.editedItem) {
            return;
        }
        if (val) {
            if (this.permissions.length === 0) {
                setTimeout(() => {
                    this.onIsAdminChange(val);
                }, 10);
                return;
            }
            this.checkedPermissions = this.permissions.map((el) => el.id);
        } else {
            this.checkedPermissions = [];
        }
    }
}
