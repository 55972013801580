import {Package} from '@/modules/admin/shared/models/subscriptions/package';

export enum PlanPeriod {
    Month = 'month',
    Year = 'year',
}

export interface SubscriptionPlan {
    id: number;
    name: string;
    description: string;
    period: PlanPeriod;
    package: Package;
    price: number;
    active: false;
    created_at: string;
    updated_at: string;
}
