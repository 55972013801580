
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {getDeveloperBillingAddress, setBillingAddress, setNewBillingAddress} from '@/modules/admin/shared/services';
import {FormRules} from '@/shared/validation/form-rules';
import ActionConfirmDialog from '@/shared/components/dialogs/action-confirm-dialog.vue';
import {AxiosResponse} from 'axios';
import {ErrorPayload} from '@/shared/payloads/error-payload';
import {ModuleShowPayload} from '@/shared/state/template/module-payloads';
import {ClientDataRequest} from '@/modules/admin/shared/requests/client-data-request';

@Component({
    components: {
        ActionConfirmDialog,
    },
})
export default class FormComponent extends Vue {
    @Prop(Number) public subscriptionId!: number;
    public loadingDeveloper = false;
    public startFormValues: any[] = [];
    public changeBillingAddressDialog = false;
    public formRules = new FormRules();
    public form: any = null;
    public formInputs: any[] = [
        {
            clientFieldName: 'first_name',
            orderFieldName: 'name',
            value: '',
            label: 'Imię',
            rules: [this.formRules.required],
            autoComplete: 'given-name',
            nameInput: 'given-name',
        },
        {
            clientFieldName: 'last_name',
            orderFieldName: 'surname',
            value: '',
            label: 'Nazwisko',
            rules: [this.formRules.required],
            autoComplete: 'family-name',
            nameInput: 'family-name',
        },
        {
            clientFieldName: 'company_name',
            orderFieldName: 'company',
            value: '',
            label: 'Nazwa firmy',
            rules: [this.formRules.required, this.formRules.maximumText(100)],
            counter: 100,
            autoComplete: 'organization',
            nameInput: 'organization',
        },
        {
            clientFieldName: 'address',
            orderFieldName: 'address',
            value: '',
            label: 'Adres',
            rules: [this.formRules.required],
            autoComplete: 'street-address',
            nameInput: 'street-address',
        },
        {
            clientFieldName: 'zip',
            orderFieldName: 'code',
            value: '',
            label: 'Kod pocztowy',
            rules: [this.formRules.required],
            autoComplete: 'postal-code',
            nameInput: 'postal-code',
            mask: '##-###',
        },
        {
            clientFieldName: 'city',
            orderFieldName: 'city',
            value: '',
            label: 'Miejscowość',
            rules: [this.formRules.required],
            autoComplete: 'address-level2',
            nameInput: 'address-level2',
        },
        {
            clientFieldName: 'nip',
            orderFieldName: 'nip',
            value: '',
            label: 'NIP',
            rules: [this.formRules.required, this.formRules.numberType, this.formRules.fixedLengthText(10)],
            counter: '10',
            autoComplete: 'nip',
            nameInput: 'nip',
        },
    ];
    public loading: boolean = false;
    public valid: string = '';
    public error: string = '';
    public errorMessages: any = {};

    public mounted() {
        this.form = this.$refs.billingAddressForm;
        this.fillInputsByDeveloperBillingAddress();
    }

    public clearError(name: string) {
        if (this.errorMessages[name]) {
            this.$delete(this.errorMessages, name);
        }
    }

    public fillInputsByDeveloperBillingAddress() {
        this.loadingDeveloper = true;
        getDeveloperBillingAddress()
            .then((response: ModuleShowPayload<ClientDataRequest>) => {
                if (response.data) {
                    this.loadingDeveloper = false;

                    const data = response.data.billing_address ? response.data.billing_address : response.data;

                    this.formInputs.forEach((e, index) => {
                        this.formInputs[index].value = data[e.clientFieldName];
                    });

                    this.startFormValues = this._.cloneDeep(this.formInputs);
                }
            });
    }

    public currentDataIsEqualToStartData() {
        return this._.isEqual(this.formInputs, this.startFormValues);
    }

    public async submitBillingAddress() {
        await this.form.validate();

        if (this.valid) {
            if (this.currentDataIsEqualToStartData()) {
                await this.setBillingAddress();
            } else {
                this.changeBillingAddressDialog = true;
            }
        }
    }

    public async setBillingAddress() {
        this.loading = true;
        setBillingAddress(this.parseBillingAddress(this.formInputs, 'orderFieldName'), this.subscriptionId)
            .then((response: AxiosResponse) => {
                if (response.status === 202) {
                    this.loading = false;
                    this.$emit('selected');
                }
            })
            .catch((e: ErrorPayload) => {
                this.loading = false;
                this.error = e.message;
                this.errorMessages = e.errors;
            });
    }

    public async setNewBillingAddressAndSend() {
        this.loading = true;
        const requestData = {
            billing_address: this.parseBillingAddress(this.formInputs, 'clientFieldName'),
        };
        setNewBillingAddress(requestData)
            .then((response: AxiosResponse) => {
                if (response.status === 202) {
                    this.changeBillingAddressDialog = false;
                    this.setBillingAddress();
                }
            });
    }

    public parseBillingAddress(data: any[], fieldType: string) {
        const billingAddress: any = {};
        data.forEach((e) => {
            billingAddress[e[fieldType]] = e.parseValue ? e.parseValue(e.value) : e.value;
        });
        return billingAddress;
    }

    public comeBackToStartFormValues() {
        this.formInputs = this._.cloneDeep(this.startFormValues);
        this.changeBillingAddressDialog = false;
    }
}
