
import { Component, Prop} from 'vue-property-decorator';
import { User } from '@/modules/admin/shared/models/user';
import { mdiEmail } from '@mdi/js';
import Vue from 'vue';

@Component({})
export default class UserCard extends Vue {
    @Prop(String) public declare id: string;
    @Prop(Object) public declare item: User;
    public store = this.$store.state.adminState;
    public icons: object = {
        mdiEmail,
    };

    get roles() {
        return this.item && this.item.roles ? this.item.roles : [];
    }

    get emailUrl(): string {
        return this.item && this.item.email ? 'mailto:' + String(this.item.email) : '';
    }
}
