import {PlanPeriod} from '../models/subscriptions/subscription-plan';

export const parsePlanPeriod = (period: PlanPeriod) => {
    switch (period) {
        case PlanPeriod.Month:
            return 'Miesięczny';
        case PlanPeriod.Year:
            return 'Roczny';
    }
};
