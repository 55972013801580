import {MediaFile} from '@/modules/media/shared/models/media-file';

export class UserDataRequest {
    public id: number = 0;
    // tslint:disable-next-line:variable-name
    public company_name: string = '';
    public name: string = '';
    public nip: string = '';
    public email: string = '';
    public password?: string = '';
    // tslint:disable-next-line:variable-name
    public avatar_photo: string = '';
    // tslint:disable-next-line:variable-name
    public password_confirmation?: string = '';
    public phone: string = '';
    // tslint:disable-next-line:variable-name
    public role_ids: number[] = [];
    // tslint:disable-next-line:variable-name
    public is_admin: boolean = false;
    // tslint:disable-next-line:variable-name
    public created_at = new Date();
    // tslint:disable-next-line:variable-name
    public updated_at = new Date();

}
