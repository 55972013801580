
import {Component, Prop, Vue} from 'vue-property-decorator';
import { FormRules } from '@/shared/validation/form-rules';
@Component
export default class ConfirmWithDataDialog extends Vue {
    public errorMessage: string = '';
    public yes = false;
    public formRules = new FormRules();
    @Prop(Boolean) private active!: boolean;
    @Prop(String) private size!: string;
    @Prop(String) private title!: string;
    @Prop(String) private warning!: string;
    @Prop(String) private confirmTitle!: string;
    @Prop(String) private cancelTitle!: string;
    @Prop(String) private dataTitle!: string;
    @Prop(String) private info!: string;
    @Prop(Boolean) private loading!: boolean;
    @Prop(Boolean) private disabled!: boolean;
    @Prop(String) private expectedData!: string;
    private inputData: string = '';

    get dialogSize() {
        switch (this.size) {
            case 'medium':
                return '700px';
            case 'big':
                return '900px';
            default:
                return '600px';
        }
    }

    public confirmAction() {
        if ( this.inputData !== '' && this.inputData === this.expectedData) {
            this.$emit('confirmed');
            this.errorMessage = '';
        } else {
            this.errorMessage = 'Niepoprawne dane';
        }
    }

    public cancelAction() {
        this.inputData = '';
        this.errorMessage = '';
        this.$emit('canceled');
    }
}
