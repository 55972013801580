
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {getPlans, setPlan} from '@/modules/admin/shared/services';
import {ErrorPayload} from '@/shared/payloads/error-payload';
import {parsePrice} from '@/shared/helpers';
import {AxiosResponse} from 'axios';
import {PlanPeriod, SubscriptionPlan} from '@/modules/admin/shared/models/subscriptions/subscription-plan';
import {SummaryPayload} from '@/modules/admin/shared/models/subscriptions/summary-payload';

@Component
export default class PlansComponent extends Vue {
    public planPeriod = PlanPeriod;
    public parsePrice = parsePrice;
    public plans: SubscriptionPlan[] = [];

    public selectedPlanPeriod: string = PlanPeriod.Month;
    public selectedPlan: number | null = null;

    public isBrutto: boolean = false;

    public loadingPlans: boolean = false;
    public updatingPlan: boolean = false;
    public error: string = '';
    public errorMessages: object = {};
    public greatestPlanOfHistory = null;

    @Prop(Number) private step!: number;
    @Prop(Number) private subscriptionId!: number;
    @Prop(Object) private summaryData!: SummaryPayload;

    public mounted() {
        this.getPlans();
    }

    get currentPlans() {
        return this.plans.filter((plan: SubscriptionPlan) => plan.period === this.selectedPlanPeriod);
    }

    public canChoose(packageId: number) {
        const greatestPlanOfHistory: number | null = this.greatestPlanOfHistory;
        return greatestPlanOfHistory ? greatestPlanOfHistory > packageId : false;
    }

    public async getPlans() {
        this.loadingPlans = true;
        getPlans()
            .then((response) => {
                if (response.data) {
                    this.plans = response.data;
                }
                this.loadingPlans = false;
            })
            .catch((e: ErrorPayload) => {
                this.error = e.message;
                this.errorMessages = e.errors;
            });
    }

    public async updatePlan(id: number) {
        this.updatingPlan = true;
        this.selectedPlan = id;

        setPlan(id)
            .then((response: AxiosResponse) => {
                if (response.status === 202) {
                    this.updatingPlan = false;
                    this.$emit('selected');
                }
            })
            .catch((e: ErrorPayload) => {
                this.error = e.message;
                this.errorMessages = e.errors;
            });
    }

    @Watch('summaryData')
    private onSummaryData() {
        if (this.summaryData.plan) {
            this.selectedPlan = this.summaryData.plan.id;
            this.selectedPlanPeriod = this.summaryData.plan.period;
        }
    }
}
