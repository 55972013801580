

import Vue from 'vue';
import {Component, Prop} from 'vue-property-decorator';
import {OrderItem} from '@/modules/admin/shared/models/orders/order-item';
import {parsePrice} from '@/shared/helpers';

@Component
export default class SummaryOrderItem extends Vue {
    @Prop({type: Boolean, default: true}) public removable!: boolean;
    @Prop({type: Boolean, default: true}) public editable!: boolean;
    @Prop(Object) public order!: OrderItem;
    public parsePrice = parsePrice;

    get type() {
        switch (this.order.sellable_type) {
            case 'subscription':
                return 'Subskrypcja';
            default:
                return 'Nieznany';
        }
    }
}

