
import {Component, Inject, Prop, VModel, Vue} from 'vue-property-decorator';
import humanizeDuration from 'humanize-duration';

import {parseDate, parsePrice} from '@/shared/helpers';
import {getPayLink} from '@/modules/admin/shared/services';
import {actionsTypes} from '@/modules/admin/shared/state';
import {SubscriptionsMeta} from '@/modules/admin/shared/models/subscriptions/subscriptions-meta';
import {Subscription} from '@/modules/admin/shared/models/subscriptions/subscription';

@Component
export default class SubscriptionCard extends Vue {
    @VModel({type: Object}) public subscription!: Subscription;
    @Prop(Boolean) public disabled!: boolean;
    @Prop({type: Boolean, default: true}) public active!: boolean;
    @Prop(Object) public meta!: SubscriptionsMeta;
    @Inject() public readonly permissionCheck!: (arg1: string) => boolean;
    public parseDate = parseDate;
    public parsePrice = parsePrice;
    public currentTab = 0;
    public loading = false;

    get isDarkTheme() {
        return this.$vuetify.theme.dark;
    }

    get isDisabled() {
        return !this.subscription.active &&
            !(
                this.subscription.order_status_id === 1 ||
                this.subscription.order_status_id === 2
            );
    }

    get currentSize() {
        return this.meta.used_media_size_bytes;
    }


    get maxSize() {
        const maxSize = this.meta.max_media_size;
        if (maxSize === 0) {
            return '∞';
        } else {
            return maxSize;
        }
    }

    get maxUsers() {
        const maxUsers = this.meta.max_users;
        if (maxUsers === 0) {
            return '∞';
        } else {
            return maxUsers;
        }
    }

    get maxInvestments() {
        const maxInvestments = this.meta.max_investments;
        if (maxInvestments === 0) {
            return '∞';
        } else {
            return maxInvestments;
        }
    }

    get activeUsers() {
        return this.meta && this.meta.active_users
            ? Number(this.meta.active_users)
            : '-';
    }

    get percentageOfUsedLimitUsers() {
        const maxUsers = Number(this.maxUsers);
        const activeUsers = Number(this.activeUsers);
        if (!isNaN(maxUsers) && !isNaN(activeUsers)) {
            return Math.round((activeUsers / maxUsers) * 100) + '%';
        }
        return '';
    }

    get activeInvestments() {
        return this.meta && this.meta.investments_count
            ? Number(this.meta.investments_count)
            : '-';
    }

    get percentageOfUsedLimitInvestments() {
        const maxInvestments = Number(this.maxInvestments);
        const activeInvestments = Number(this.activeInvestments);
        if (!isNaN(maxInvestments) && !isNaN(activeInvestments)) {
            return Math.round((activeInvestments / maxInvestments) * 100) + '%';
        }
        return '';
    }

    get percentageOfUsedLimitMemory() {
        const maxSize = Number(this.meta ? this.meta.max_media_size_bytes : 0);
        const currentSize = Number(this.meta ? this.meta.used_media_size_bytes : 0);
        if (!isNaN(maxSize) && !isNaN(currentSize)) {
            return Math.round((currentSize / maxSize) * 100) + '%';
        }
        return '';
    }

    public pay(subscriptionId: number) {
        this.loading = true;
        getPayLink(subscriptionId).then((res) => {
            this.loading = false;
            if (res.redirect_url) {
                window.location.replace(res.redirect_url);
            } else {
                this.$store.dispatch(actionsTypes.SNACKBAR_TRIGGER, {
                    type: 'error',
                    text: 'Coś poszło nie tak',
                });
            }
        });
    }

    public isPermitted(actionName: string) {
        return this.permissionCheck(`admin.subscriptions.${actionName}`);
    }

    public days_between(date: any) {
        const today: any = new Date();
        const endDate: any = new Date(date);
        const diffTime = Math.abs(endDate - today);

        return humanizeDuration(diffTime, {units: ['d'], round: true, language: 'pl'});
    }

    public byteToGigaByte(n: any) {
        return (n / Math.pow(10, 9));
    }

}
