var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-card',{staticClass:"fill-height",attrs:{"flat":""}},[_c('v-card-title',{staticClass:"d-flex justify-space-between"},[_c('span',[_vm._v(" Dane firmy: "),(_vm.loading)?_c('v-progress-circular',{staticClass:"ml-2",attrs:{"indeterminate":"","color":"primary"}}):_vm._e()],1),_c('v-fade-transition',[(_vm.active)?_c('subform-action-buttons',{attrs:{"edit-mode":_vm.editMode},on:{"edit":function($event){return _vm.toggleEditMode()},"cancel":function($event){return _vm.abortEdition()},"save":function($event){return _vm.submit()}}}):_vm._e()],1)],1),(!_vm.loading && _vm.requestData !== null)?_c('v-card-text',{staticClass:"d-flex flex-column",class:_vm.isMApp ? 'py-1 px-4' : 'py-4 px-8'},[_c('v-row',{staticClass:"py-2",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('image-input',{attrs:{"previewImage":_vm.previewImage,"disabled":!_vm.editMode,"accept":"image/png","label":"Logo","loading":_vm.logoLoading},model:{value:(_vm.logo),callback:function ($$v) {_vm.logo=$$v},expression:"logo"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('gus-data-by-nip',{staticClass:"mb-0",attrs:{"textFieldProps":{
                        disabled: !_vm.editMode,
                        label: 'NIP',
                        required: true,
                        flat: true,
                        style: !_vm.editMode ? 'font-size: 28px; max-height: 62px;' : ' max-height: 62px;',
                        rules: [_vm.formRules.required, _vm.formRules.fixedLengthText(10)],
                        'v-mask': _vm.mask.nip,
                    }},on:{"accept":function($event){return _vm.fillData()},"search-result":function($event){return _vm.onNipResult($event)}},nativeOn:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.fillData()}},model:{value:(_vm.requestData.nip),callback:function ($$v) {_vm.$set(_vm.requestData, "nip", $$v)},expression:"requestData.nip"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"company-name mt-0 ml-5 company-data-autocomplete",attrs:{"disabled":"","rules":[_vm.formRules.required],"value":_vm.requestData.company_name,"suffix":_vm.placeHolders.company_name,"readonly":"","persistent-placeholder":""}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"ml-5 autocomplete",attrs:{"disabled":"","suffix":_vm.placeHolders.regon,"persistent-placeholder":"","label":"REGON","rules":[_vm.formRules.multipleFixedLengthText([9, 14])],"readonly":""},model:{value:(_vm.requestData.regon),callback:function ($$v) {_vm.$set(_vm.requestData, "regon", $$v)},expression:"requestData.regon"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-slide-x-transition',[(_vm.requestData.krs || _vm.placeHolders.krs)?_c('v-text-field',{staticClass:"ml-5 autocomplete",style:(_vm.requestData.krs !== '' && _vm.placeHolders.krs === ''
                                ? 'text-decoration: line-through; color: var(--v-primary-base) !important;'
                                : ''),attrs:{"disabled":"","suffix":_vm.placeHolders.krs,"label":"KRS","rules":[_vm.formRules.fixedLengthText(10)],"readonly":""},model:{value:(_vm.requestData.krs),callback:function ($$v) {_vm.$set(_vm.requestData, "krs", $$v)},expression:"requestData.krs"}}):_vm._e()],1),_c('v-divider',{staticClass:"mb-8"})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('## #### #### #### #### #### #####'),expression:"'## #### #### #### #### #### #####'"}],style:(!_vm.editMode ? 'font-size: 28px; max-height: 62px;' : ' max-height: 62px;'),attrs:{"disabled":!_vm.editMode,"rules":[_vm.formRules.fixedLengthText(26, true)],"suffix":_vm.placeHolders.bank_account_number,"persistent-placeholder":"","label":"Numer rachunku bankowego"},model:{value:(_vm.requestData.bank_account_number),callback:function ($$v) {_vm.$set(_vm.requestData, "bank_account_number", $$v)},expression:"requestData.bank_account_number"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"disabled":!_vm.editMode,"rules":[_vm.formRules.fixedLengthText(9)],"suffix":_vm.placeHolders.bdo,"persistent-placeholder":"","label":"Numer BDO"},model:{value:(_vm.requestData.bdo),callback:function ($$v) {_vm.$set(_vm.requestData, "bdo", $$v)},expression:"requestData.bdo"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{staticClass:"pt-0 mt-4",attrs:{"no-resize":"","disabled":!_vm.editMode,"label":"Opis","required":""},model:{value:(_vm.requestData.description),callback:function ($$v) {_vm.$set(_vm.requestData, "description", $$v)},expression:"requestData.description"}})],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }