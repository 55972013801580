import { textMask } from '@/shared/config/masks';
import { FormRules } from '@/shared/validation/form-rules';
import _ from 'lodash';
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Client } from '../../shared/models/client';
import { ClientDataRequest } from '../../shared/requests/client-data-request';

@Component
export default class ProfileSubform extends Vue {
    @Prop(Object) public itemData!: ClientDataRequest;
    @Prop(Boolean) public active!: boolean;
    public editMode = false;
    public itemDataKeysToChange: string[] | null = null;
    public requestData: ClientDataRequest | null = null;
    public formRules = new FormRules();
    public mask = textMask;
    public valid = false;

    public placeHolders = {} as ClientDataRequest;

    public toggleEditMode() {
        this.editMode = !this.editMode;
    }

    get loading() {
        return !this.requestData || !this.itemData;
    }

    @Watch('itemData')
    public onItemDataChange(val: ClientDataRequest) {
        this.requestData = _.cloneDeep(this.itemData);
        this.$forceUpdate();
    }

    public submit(data?: any) {
        this.toggleEditMode();
        // console.log(this.requestData, this.itemData as T);
        let changes;
        if (!data) {
            changes = _.pickBy(this.requestData, (value: any, key: string) => {
                if (this.itemDataKeysToChange && !this.itemDataKeysToChange?.includes(key)) {
                    return false;
                }
                return (this.itemData as any)[key] !== value;
            });
        } else {
            changes = data;
        }

        // removing spaces from nrb's
        if (changes.bank_account_number) {
            this.$set(changes as any, 'bank_account_number', changes.bank_account_number.replace(/\s/g, ''));
        }
        if (changes.billing_address && changes.billing_address.bank_account_number) {
            this.$set(
                changes.billing_address as any,
                'bank_account_number',
                changes.billing_address.bank_account_number.replace(/\s/g, ''),
            );
        }
        this.requestData = null;

        this.$emit('submit', changes);
    }
    public abortEdition() {
        this.editMode = false;
        this.requestData = null;
        this.$emit('abort');
    }

    public fillData() {
        // @ts-ignore-next-line
        for (const k of Object.keys(this.placeHolders)) {
            if (k === 'name') {
                this.$set(this.requestData as any, 'company_name', (this.placeHolders as any)[k]);
                continue;
            }
            this.$set(this.requestData as any, k, (this.placeHolders as any)[k]);
        }
        this.placeHolders = {} as ClientDataRequest;
        this.$forceUpdate();
    }

    @Watch('active')
    public onActiveChange(val: boolean) {
        this.$nextTick(() => {
            if (!val && this.editMode) {
                this.abortEdition();
            }
        });
    }
}
