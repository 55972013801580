
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {getPaymentMethods, setPaymentMethod} from '@/modules/admin/shared/services';
import {ErrorPayload} from '@/shared/payloads/error-payload';
import {AxiosResponse} from 'axios';
import {ModuleFetchPayload} from '@/shared/state/template/module-payloads';
import {PaymentMethod} from '@/modules/admin/shared/models/subscriptions/payment-method';
import {SummaryPayload} from '@/modules/admin/shared/models/subscriptions/summary-payload';

@Component
export default class PaymentMethods extends Vue {
    @Prop(Number) public subscriptionId!: number;
    @Prop(Object) public summaryData!: SummaryPayload;
    public paymentsMethods: PaymentMethod[] = [];
    public selectedPaymentMethod: number | null = null;
    public updatingPaymentMethod: boolean = false;
    public loadingPaymentMethods: boolean = false;
    public form: any = null;
    public error: string = '';
    public errorMessages: object = {};
    public valid: boolean = false;

    public mounted() {
        this.form = this.$refs.paymentMethodsForm;
        this.getPaymentsMethods();
    }

    public async updatePaymentMethod() {
        await this.form.validate();

        if (this.valid) {
            if (this.selectedPaymentMethod) {
                this.updatingPaymentMethod = true;
                setPaymentMethod(this.selectedPaymentMethod, this.$props.subscriptionId)
                    .then((response: AxiosResponse) => {
                        if (response.status === 202) {
                            this.updatingPaymentMethod = false;
                            this.$emit('selected');
                        }
                    })
                    .catch((e: ErrorPayload) => {
                        this.error = e.message;
                        this.errorMessages = e.errors;
                    });
            }
        }
    }

    public async getPaymentsMethods() {
        this.loadingPaymentMethods = true;
        getPaymentMethods()
            .then((response: ModuleFetchPayload<PaymentMethod>) => {
                this.loadingPaymentMethods = false;
                if (response.data) {
                    this.paymentsMethods = response.data;
                    this.selectedPaymentMethod = this.paymentsMethods[0].id;
                }
            })
            .catch((e: ErrorPayload) => {
                this.error = e.message;
                this.errorMessages = e.errors;
            });
    }

    @Watch('summaryData')
    private onSummaryData() {
        if (this.summaryData.payment_method) {
            this.selectedPaymentMethod = this.summaryData.payment_method.id;
        }
    }
}
