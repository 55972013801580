var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',[_c('items-table',{attrs:{"headers":_vm.headers,"props":_vm.props,"fetch-action":_vm.fetchAction,"mutation-types":_vm.mutationTypes,"remove-action":_vm.removeAction,"filter-config":_vm.filterConfig},scopedSlots:_vm._u([{key:"item.number",fn:function({ item }){return [_c('a',{on:{"click":function($event){return _vm.showOrderDialog(item.id)}}},[_vm._v(_vm._s(item.number ? item.number : ''))]),(_vm.showOrderDialogs === item.id)?_c('v-dialog',{attrs:{"max-width":"500px","min-height":"500px"},model:{value:(_vm.showOrderDialogs),callback:function ($$v) {_vm.showOrderDialogs=$$v},expression:"showOrderDialogs"}},[_c('order-details',{attrs:{"id":item.id}})],1):_vm._e()]}},{key:"item.link",fn:function({ item }){return [_c('router-link',{attrs:{"to":"/admin/orders"}},[_vm._v("Faktura")])]}},{key:"item.user",fn:function({ item }){return [_c('a',{on:{"click":function($event){_vm.showUserDialog = true;
                    _vm.editedItem = item.user;}}},[_vm._v(_vm._s(item.user ? item.user.name : ''))])]}},{key:"item.status",fn:function({ item }){return [_c('v-chip',{attrs:{"small":"","color":_vm.getColor(item.status.id)}},[_vm._v(_vm._s(item.status && item.status.name ? item.status.name : ''))])]}},{key:"item.amount",fn:function({ item }){return [_c('div',[_vm._v(_vm._s(_vm.parsePrice(item.amount)))])]}},{key:"item.payment",fn:function({ item }){return [_c('div',[_vm._v(" "+_vm._s(item.payment && item.payment.payment_method && item.payment.payment_method.name ? item.payment.payment_method.name : '')+" ")])]}},{key:"item.action",fn:function({ item }){return [(item.payment && item.payment.id)?_c('tooltip-btn',{staticClass:"pa-2",attrs:{"tooltipText":!_vm.isPermitted('download')
                        ? 'Brak uprawnień'
                        : item.status.id < 4
                        ? 'Brak faktury'
                        : 'Pobierz fakturę',"small":"","icon":"","disabled":item.status.id < 4 || !_vm.isPermitted('download')},on:{"click":function($event){return _vm.downloadInvoice(item.payment.order_id)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-arrow-collapse-down")])],1):_vm._e()]}}])}),_c('v-dialog',{attrs:{"max-width":"500px","min-height":"500px"},model:{value:(_vm.showUserDialog),callback:function ($$v) {_vm.showUserDialog=$$v},expression:"showUserDialog"}},[_c('user-details',{attrs:{"id":String(_vm.editedItem.id)},on:{"canceled":function($event){_vm.showUserDialog = false},"itemChanged":(id) => {
                    _vm.editedItem.id = id;
                }}})],1),_c('v-dialog',{attrs:{"max-width":"500px","min-height":"500px"},model:{value:(_vm.showOrderDialogFromUrl),callback:function ($$v) {_vm.showOrderDialogFromUrl=$$v},expression:"showOrderDialogFromUrl"}},[_c('order-details',{attrs:{"id":_vm.orderIdFromUrl},on:{"close":function($event){_vm.showOrderDialogFromUrl = false}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }