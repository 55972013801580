
import {Component, Prop, Vue} from 'vue-property-decorator';
import ListComponent from '@/shared/components/layout/list/list-component';
import ItemsTable from '@/shared/components/layout/list.vue';
import { OrderItemResponse } from '../module';
import { DataRequest } from '@/shared/types';
import { ordersModule } from '../modele';
import UserDetails from '../../users/card.vue';
import { ListFilter } from '@/shared/types';
import OrderDetails from './order-details.vue';
import { ModuleProps } from '@/shared/state/template/module-props';
import { httpClient } from '@/shared/services';
import { showFile } from '@/shared/helpers';
import TooltipBtn from '@/shared/components/elements/tooltip-btn.vue';

@Component({
    components: {
        ItemsTable,
        UserDetails,
        OrderDetails,
        TooltipBtn,
    },
})
export default class OrdersList extends ListComponent<OrderItemResponse, DataRequest> {
    @Prop(Number) public orderId!: number;

    public actionTypes = ordersModule.actionsTypes;
    public mutationTypes = ordersModule.mutationsTypes;
    public fetchAction = this.actionTypes.FETCH_DATA;
    public store = this.$store.state.ordersState;
    public removeAction = '';
    public props: ModuleProps = ordersModule.moduleProps;
    public headers = [
        { text: 'Id', value: 'id' },
        { text: 'Numer zamówienia', value: 'number' },
        { text: 'Metoda płatności', value: 'payment' },
        { text: 'Użytkownik', value: 'user' },
        { text: 'Kwota (netto)', value: 'amount' },
        { text: 'Status zamówienia', value: 'status' },
        { text: 'Akcje', value: 'action', align: 'center' },
    ];

    public showUserDialog = false;
    public showOrderDialogs: number | false = false;
    public orderIdFromUrl: number|null = null;
    public showOrderDialogFromUrl = false;
    public filterConfig: ListFilter[] = [
        {
            name: 'order_status_id',
            label: 'Status zamówienia',
            type: 'select',
            description: 'Wybierz typ statusu',
            data: [
                { name: '2', label: 'W trakcie realizacji', value: false },
                {
                    name: '1',
                    label: 'Oczekujące na płatność',
                    value: false,
                },
                { name: '3', label: 'Zrealizowana', value: false },
            ],
        },
    ];

    public showOrderDialog(id: any) {
        this.showOrderDialogs = id;
    }

    public downloadInvoice(orderId: number) {
        httpClient
            .get(`/api/v1/orders/${orderId}/invoice`, {
                responseType: 'arraybuffer',
                headers: {
                    Accept: 'application/pdf',
                },
            })
            .then((r: any) => showFile(r.data))
            .catch((error) => {
                this.$store.commit('SHOW_SNACKBAR', {
                    type: 'error',
                    text: 'Nie można pobrać faktury',
                });
            });
    }

    public getColor(status: number) {
        switch (status) {
            case 1:
                return 'warning';
            case 2:
                return 'primary';
            case 3:
                return 'success';
        }
    }

    public mounted() {
        const orderId = this.orderId;

        // if url contains order id
        if (orderId) {
            this.showOrderDialogFromUrl = true;
            this.orderIdFromUrl = Number(orderId);
        }
    }

    public isPermitted(actionName: string) {
        return this.permissionCheck(`admin.orders.${actionName}`);
    }
}
