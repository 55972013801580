
import ProfileSubform from './profile-subform';
import { ClientDataRequest } from '@/modules/admin/shared/requests/client-data-request';
import { Component, Mixins } from 'vue-property-decorator';
import SubformActionButtons from './subform-action-buttons.vue';
import { httpClient } from '@/shared/services';

@Component({
    components: {
        SubformActionButtons,
    },
})
export default class ContactData extends Mixins(ProfileSubform) {

    public gusData: null | ClientDataRequest = null;
    public isMApp = (this.$vuetify.breakpoint.name === 'xs' || this.$vuetify.breakpoint.name === 'sm');

    public created() {
        // @Watch('itemData.nip') trows error when itemData is undefined
        this.$watch('itemData.nip', this.onNipChange);
    }

    public placeholdersFromGus() {
        if (this.gusData === null) {
            return;
        }
        for (const k of Object.keys(this.gusData)) {
            if (k === 'zip') {
                if (
                    (this.requestData as any)[k] !== this.applyZipCodeMask((this.gusData as any)[k]) &&
                    (this.gusData as any)[k]
                ) {
                    this.$set(this.placeHolders as any, k, (this.gusData as any)[k]);
                }
            } else if ((this.requestData as any)[k] !== (this.gusData as any)[k] && (this.gusData as any)[k]) {
                this.$set(this.placeHolders as any, k, (this.gusData as any)[k]);
            }
        }
    }
    public clearPlaceholders() {
        this.placeHolders = {} as ClientDataRequest;
    }
    public fillFromGus() {
        this.fillData();
    }
    public onNipChange(val: string) {
        if (!val) {
            return;
        }
        httpClient
            .get(`api/v1/contractors/gus/search-by-nip/${val}`)
            .then((response: any) => {
                if (response.data.success) {
                    this.gusData = response.data.data;
                } else {
                    this.gusData = null;
                }
            })
            .catch((err) => {
                this.gusData = null;
            });
    }

    public applyZipCodeMask(val: string | undefined) {
        if (!val) {
            return '';
        }
        return val.slice(0, 2) + '-' + val.slice(2);
    }
}
