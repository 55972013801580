
import { Component, Inject } from 'vue-property-decorator';
import ListComponent from '@/shared/components/layout/list-component';
import ActionConfirmDialog from '@/shared/components/dialogs/action-confirm-dialog.vue';
import { rolesListHeaders } from '@/modules/admin/shared/config';
import { ErrorBoundary } from '@/shared/components';
import { actionsTypes } from '@/modules/admin/shared/state';
import ListToolbar from '@/shared/components/layout/list/list-toolbar.vue';
import FilterBar from '@/shared/components/layout/list/filter-bar.vue';
import { User } from '@/modules/admin/shared/models/user';
import NameReduce from '@/shared/components/elements/name-reduce.vue';
import { httpClient } from '@/shared/services';
import TooltipBtn from '@/shared/components/elements/tooltip-btn.vue';

@Component({
    components: {
        ErrorBoundary,
        ActionConfirmDialog,
        ListToolbar,
        FilterBar,
        NameReduce,
        TooltipBtn,
    },
})
export default class RolesList extends ListComponent<User> {
    @Inject() public readonly permissionCheck!: (...permissions: string[]) => boolean;
    public headers: object[] = rolesListHeaders;
    public store = this.$store.state.adminState;
    public fetchAction = actionsTypes.FETCH_ROLE_DATA;
    public removeAction = actionsTypes.REMOVE_ROLE;

    get roles() {
        return this.store.roles;
    }

    public created() {
        this.fetchData();
    }

    public rowClasses(item: User): string[] {
        if (item.trashed) {
            return ['table-row-disabled'];
        }

        return [];
    }

    public createItem() {
        this.goToItem(`role-details`, {
            id: 'new',
        });
    }

    public remove(id: string) {
        httpClient.delete(`api/v1/users/roles/${id}`).then((res) => {
            if (res.status === 204) {
                this.$store.commit('SHOW_SNACKBAR', {
                    type: 'success',
                    text: 'Pomyślnie usunięto element',
                });
            } else {
                this.$store.commit('SHOW_SNACKBAR', {
                    type: 'success',
                    text: 'Coś poszło nie tak',
                });
            }
            this.fetchData();
        });
    }

    public isPermitted(actionName: string) {
        return this.permissionCheck(`admin.roles.${actionName}`);
    }
}
