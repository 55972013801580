
import Vue from 'vue';
import Component from 'vue-class-component';
import { FooterConfigRequest, HeaderConfigRequest } from '../../shared/requests/header-and-footer-request';
// @ts-ignore
import pdf from 'vue-pdf-app';
import 'vue-pdf-app/dist/icons/main.css';
import { httpClient } from '@/shared/services';
import { Client } from '@/modules/client/shared/models/client';
import { Watch } from 'vue-property-decorator';
import { timeStamp } from 'console';
import axios from 'axios';

let controller = new AbortController();

@Component({
    components: { pdf },
})
export default class FootersAndHeadersForm extends Vue {
    get isDarkTheme() {
        return this.$vuetify.theme.dark;
    }

    get isMobile() {
        switch (this.$vuetify.breakpoint.name) {
            case 'xs':
                return true;
            case 'sm':
                return true;
            case 'md':
                return false;
            case 'lg':
                return false;
            case 'xl':
                return false;
        }
    }

    get isSubtitleDisabled() {
        if (this.headerData) {
            if (this.headerData.title) {
                return false;
            } else {
                return true;
            }
        }
        return false;
    }

    get headerAlignment() {
        // const baseAligments = [{}];
        if (this.headerData.logo && this.headerData.title) {
            return [
                { name: 'Na skrajach', value: 'justify' },
                { name: 'Na Środku', value: 'center' },
                { name: 'Prawo', value: 'end' },
                { name: 'Lewo', value: 'start' },
            ];
        } else if (this.headerData.logo || this.headerData.title) {
            if (this.headerData.alignment === 'justify') {
                this.headerData.alignment = 'center';
            }
            return [
                { name: 'Na Środku', value: 'center' },
                { name: 'Prawo', value: 'end' },
                { name: 'Lewo', value: 'start' },
            ];
        } else {
            return [];
        }
    }
    get footerAlignment() {
        // const baseAligments = [{}];
        return [
            { name: 'Na Środku', value: 'center' },
            { name: 'Prawo', value: 'end' },
            { name: 'Lewo', value: 'start' },
        ];
    }

    get footerProps() {
        return [
            ...(this.clientData?.krs
                ? [
                      {
                          name: 'krs',
                          value: `KRS: ${this.clientData?.krs}`,
                      },
                  ]
                : []),
            ...(this.clientData?.nip
                ? [
                      {
                          name: 'nip',
                          value: `NIP: ${this.clientData?.nip}`,
                      },
                  ]
                : []),
            ...(this.clientData?.regon
                ? [
                      {
                          name: 'regon',
                          value: `REGON: ${this.clientData?.regon}`,
                      },
                  ]
                : []),
            ...(this.clientData?.bdo
                ? [
                      {
                          name: 'bdo',
                          value: `BDO: ${this.clientData?.bdo}`,
                      },
                  ]
                : []),
            ...(this.clientData?.phone
                ? [
                      {
                          name: 'Nr telefonu',
                          value: `TEL: ${this.clientData?.phone}`,
                      },
                  ]
                : []),
            ...(this.clientData?.bank_account_number
                ? [
                      {
                          name: 'Nr Rachunku bankowego',
                          value: `NRB: ${this.clientData?.bank_account_number}`,
                      },
                  ]
                : []),
        ];
    }

    public isClientLoading = false;
    public isPreviewLoading = false;

    public previewFetchDelay: any = null;
    public resizeTimeout: any = null;

    public exampleDocPath: any = null;
    public pdfViewerKey = Math.random();
    public pdfViewerConfig = {
        toolbar: false,
    };
    public pdfViewerApp: any = null;

    public headerData = new HeaderConfigRequest();
    public footerData = new FooterConfigRequest();

    public clientData: Client | null = null;

    public pagesRenderedHandler(pdfApp: any) {
        if (pdfApp) {
            this.pdfViewerApp = pdfApp;
        }
        setTimeout(() => {
            (this.$refs.pdfapp as any)?.$el.addEventListener('wheel', (e: any) => {
                e.stopPropagation();
            });
            pdfApp.pdfViewer.currentScaleValue = 'page-fit';
        });
    }

    public created() {
        this.getClientData();
    }

    public mounted() {
        window.addEventListener('resize', this.resizeHandler);
    }

    public blockInputWhenMaxLength(evt: any, val: string, max: number) {
        if (evt && evt.clipboardData) {
            if (!evt.clipboardData.getData('Text')) {
                return;
            }

            if (max < (evt.clipboardData.getData('Text') + val).length) {
                evt.preventDefault();
            }
        }
        if (val && val.length > max - 1) {
            if (evt.keyCode >= 48 && evt.keyCode <= 90) {
                evt.preventDefault();
            }
        }
    }

    public resizeHandler() {
        if (this.resizeTimeout) {
            clearTimeout(this.resizeTimeout);
        }

        this.resizeTimeout = setTimeout(() => {
            this.$nextTick(() => {
                if (this.pdfViewerApp && this.pdfViewerApp.pdfViewer) {
                    this.pdfViewerApp.pdfViewer.currentScaleValue = 'page-fit';
                }
            });
        }, 800);
    }

    public submit() {
        httpClient

            .put('api/v1/admin/client', { header_config: this.headerData, footer_config: this.footerData })
            .then((res) => {
                this.getClientData();
            });
    }

    public cancel() {
        this.getClientData();
    }

    public refresh() {
        this.getPreviewDocument();
    }

    public getPreviewDocument() {
        this.isPreviewLoading = true;
        httpClient
            .post(
                '/documents/header-and-footer/preview',
                { header_config: this.headerData, footer_config: this.footerData },
                {
                    signal: controller.signal,
                    responseType: 'blob',
                    headers: {},
                    method: 'POST',
                },
            )
            .then((res) => {
                const blob = new File([res.data], 'test');
                const reader = new FileReader();
                reader.readAsArrayBuffer(blob);
                reader.onload = () => {
                    this.exampleDocPath = reader.result;
                    this.pdfViewerKey = Math.random();
                    this.isPreviewLoading = false;
                };
                reader.onerror = (error) => {
                    this.isPreviewLoading = false;
                    return null;
                };
            })
            .catch((err) => {
                return;
            });
    }

    public getClientData() {
        this.isPreviewLoading = true;
        this.isClientLoading = true;
        httpClient.get('api/v1/admin/client').then(async (res) => {
            this.clientData = res.data.data;
            if (this.clientData) {
                if (this.clientData.header_config) {
                    this.headerData = await JSON.parse(this.clientData.header_config);
                    if (!this.clientData.logo_path) {
                        this.headerData.logo = false;
                    }
                } else {
                    this.headerData = new HeaderConfigRequest();
                }
                if (this.clientData.footer_config) {
                    this.footerData = await JSON.parse(this.clientData.footer_config);
                } else {
                    this.footerData = new FooterConfigRequest();
                }
                this.isClientLoading = false;
            }
            this.$forceUpdate();
        });
    }

    public fillTitle() {
        this.headerData.title = this.clientData?.company_name.substring(0, 48) || this.headerData.title;
    }

    @Watch('headerData', { deep: true })
    public onHeaderChange() {
        if (this.previewFetchDelay) {
            clearTimeout(this.previewFetchDelay);
        }
        this.previewFetchDelay = setTimeout(() => {
            controller.abort();
            controller = new AbortController();
            this.getPreviewDocument();
        }, 200);
    }
    @Watch('footerData', { deep: true })
    public onFooterChange() {
        if (this.previewFetchDelay) {
            clearTimeout(this.previewFetchDelay);
        }
        this.previewFetchDelay = setTimeout(() => {
            controller.abort();
            controller = new AbortController();
            this.getPreviewDocument();
        }, 200);
    }
    @Watch('footerData.props', {deep: true})
    public onFooterPropsChange(val: any) {
        if (val.length > 4) {
            val.shift();
        }
    }
}
