
import {parsePrice, showFile} from '@/shared/helpers';
import {getOrder} from '@/modules/admin/shared/services/orders';
import {getSlimOrderItem} from '@/modules/admin/shared/helpers/orders';
import {Component, Inject, Prop, Vue} from 'vue-property-decorator';
import {actionsTypes} from '@/modules/admin/shared/state';
import {httpClient} from '@/shared/services';
import TooltipBtn from '@/shared/components/elements/tooltip-btn.vue';
import AddressField from '@/shared/components/elements/address.vue';
import {ErrorPayload} from '@/shared/payloads/error-payload';

@Component({
    components: {
        TooltipBtn,
        AddressField,
    },
})
export default class OrdersDetails extends Vue {
    @Inject() public readonly permissionCheck!: (...permissions: string[]) => boolean;
    @Prop(Number) public id!: number;
    public item: any = null;
    public parsePrice = parsePrice;
    public loading: boolean = true;

    public mounted() {
        this.loading = true;

        getOrder(this.id)
            .then((res) => {
                this.item = res.data;
            })
            .catch((e: ErrorPayload) => {
                this.$store.dispatch(actionsTypes.SNACKBAR_TRIGGER, {
                    type: 'error',
                    text: 'Coś poszło nie tak',
                });
                this.$emit('close');
            })
            .finally(() => {
                this.loading = false;
            });
    }

    public downloadInvoice(orderId: number) {
        httpClient
            .get(`/api/v1/orders/${orderId}/invoice`, {
                responseType: 'arraybuffer',
                headers: {
                    Accept: 'application/pdf',
                },
            })
            .then((r: any) => showFile(r.data))
            .catch((error) => {
                this.$store.commit('SHOW_SNACKBAR', {
                    type: 'error',
                    text: 'Nie można pobrać faktury',
                });
            });
    }

    public isPermitted(actionName: string) {
        return this.permissionCheck(`admin.orders.${actionName}`);
    }
}
