export const inviteStatuses = [
    {
        type: 'sent',
        name: 'Wysyłany',
        icon: 'mdi-email',
        color: '#FBC02D',
    },
    {
        type: 'resent',
        name: 'Wysyłany ponownie',
        icon: 'mdi-email-sync',
        color: '#FBC02D',
    },
    {
        type: 'activated',
        name: 'Aktywowany',
        icon: 'mdi-account-check',
        color: '#4CAF50',
    },
    {
        type: 'expired',
        name: 'Wygasło',
        icon: 'mdi-clock',
        color: '#D32F2F',
    },
];
