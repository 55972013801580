
import { httpClient } from '@/shared/services';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
@Component({})
export default class ImageInput extends Vue {
    @Prop({ type: String }) public previewImage!: Blob | null;
    @Prop({ type: String }) public label!: string;
    @Prop({ type: Boolean }) public disabled!: boolean;
    @Prop({ type: Boolean }) public loading!: boolean;
    @Prop({ type: File }) public value!: File|null;
    @Prop({
        type: String,
        default: 'image/png, image/jpeg, image/bmp, image/svg, image/svg+xml',
    }) public accept!: string;

    get logo() {
        return this.value;
    }
    set logo(val: File|null) {
        this.$emit('input', val);
    }

    // public mounted(){
    //     this.loading = true;
    //     httpClient.get('/api/v1/admin/logo?orginal=true', {responseType:'blob'}).then((res:any)=>{
    //         this.logo = new File([res.data], 'Przesłane logo');
    //     }).finally(()=>{
    //         this.loading = false;
    //     });
    // }

    get preview() {
        if (this.logo) {
            // console.log(this.logo);
            return URL.createObjectURL(this.logo);
        }
        return null;
    }

}
