
import ProfileSubform from './profile-subform';
import { ClientDataRequest } from '@/modules/admin/shared/requests/client-data-request';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import SubformActionButtons from './subform-action-buttons.vue';
import { callSuper } from '@/shared/helpers';
import { isMap } from 'lodash';

@Component({
    components: {
        SubformActionButtons,
    },
})
export default class InvoiceData extends Mixins(ProfileSubform) {
    @Prop(Boolean) public forceUpdate!: boolean;
    public isMApp = (this.$vuetify.breakpoint.name === 'xs' || this.$vuetify.breakpoint.name === 'sm');

    public diffrentInvoiceData = false;

    public stash!: ClientDataRequest | null;

    public created() {
        if (this.itemData && this.itemData.billing_address) {
            this.diffrentInvoiceData = true;
        }
    }

    public submit() {
        callSuper(this, 'submit', { billing_address: this.diffrentInvoiceData ? this.requestData : null });
    }

    @Watch('diffrentInvoiceData')
    public onDiffrentIvnoiceDataChange(val: boolean) {
        if (!val) {
            this.fillFields();
        } else if (this.stash) {
            this.requestData = this._.cloneDeep(this.stash);
        }
    }

    @Watch('itemData')
    public onItemDataChange(val: ClientDataRequest) {
        if (!this.requestData) {
            if (val.billing_address) {
                this.requestData = this._.cloneDeep(val.billing_address);
                this.diffrentInvoiceData = true;
                this.$forceUpdate();
                return;
            }
            this.requestData = this._.cloneDeep(val);
            this.$forceUpdate();
            return;
        }
        if (!this.diffrentInvoiceData || this.forceUpdate) {
            if (val.billing_address) {
                this.requestData = this._.cloneDeep(val.billing_address);
                this.diffrentInvoiceData = true;
                this.$forceUpdate();
                return;
            }
            this.requestData = this._.cloneDeep(val);
            this.$forceUpdate();
        }
    }

    public fillFields() {
        this.stash = this._.cloneDeep(this.requestData) as ClientDataRequest;

        this.requestData = this._.cloneDeep(this.itemData);
        this.$forceUpdate();
    }

    get isEditingDisabled() {
        return (!this.diffrentInvoiceData && this.editMode) || !this.editMode;
    }
}
