

// tslint:disable

export class HeaderAndFooterRequest {
    // tslint:disable-next-line:variable-name
    public header_config = {} as HeaderAndFooterRequest ;
    public footer_config = {} as HeaderAndFooterRequest ;
    // public title: string = '';
}

// @ts-ignore
export class HeaderConfigRequest {
    public logo = false;
    public title  = '';
    public subtitle = '';
    public alignment = '';
    public reversed = false;
    // tslint:disable-next-line:variable-name
    public line_separator = false;
}
// @ts-ignore
export class FooterConfigRequest {
    public address = false;
    public props  = [];
    public text = '';
    public alignment = '';
    // tslint:disable-next-line:variable-name
    public line_separator = false;
}
