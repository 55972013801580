
import _ from 'lodash';
import { Component, Prop, Watch } from 'vue-property-decorator';
import Vue from 'vue';
import { Permission } from '../../shared/models/premission';

@Component({})
export default class PermissionsTable extends Vue {
    @Prop({ type: Array, default: () => [] }) public value!: any[];
    @Prop(Array) public permissions!: Permission[];
    @Prop(Boolean) public disabled!: boolean;
    // Do podpięcia funkcji z DAPK-1 jak będzie zaakceptowane
    public isMApp = (this.$vuetify.breakpoint.name === 'sm' || this.$vuetify.breakpoint.name === 'xs');
    public showPopover: boolean = false;
    public popoverAttachedPermissionId: number = 0;
    public popoverItems: Permission[] = [];
    public actionsList: Permission[] = [];
    public popoverActionName!: 'add' | 'delete';
    public permissionsModules: any = {};
    public actionWeight: { [key: string]: number } = {
        view: 1,
        create: 2,
        edit: 3,
        delete: 4,
    };

    /*
        The workaround of js not having pointers
    */
    // ACCESS BY action.name
    public permissionRequirements: { [key: string]: number[] } = {};
    // ACCESS BY action.name
    public permissionConnectedTo: { [key: string]: number[] } = {};
    // ACCESS BY action.name
    public permissionChildren: { [key: string]: Permission[] } = {};

    get popoverItemsGrouped() {
        return this.groupPermissionsByModule(this.popoverItems);
    }
    get allChecked() {
        return _.isEqual(
            this.actionsList.map((el) => el.id),
            this.selected,
        );
    }
    get selected() {
        return this.value;
    }
    set selected(val: number[]) {
        this.$emit('input', val);
    }

    public created() {
        /*
            This code grups and sets up premissions into

           permissionModules{
               ExampleModuleName:[
                   {
                       name,
                       display_name,
                       actions:
                       [
                           {
                                displayActionName - name for chips,
                                action_name -  E.g. .view
                                isMandatory - true for all actions that are required to use module with least useabiliy
                                module_parent - true for action that will select all madatory ones in module,
                                parent - true if action has children
                                ...(rest of permission)
                           }
                       ]
                   }
               ]
           }

        */
        const splitedPermissions = this.splitPermissionsIntoActions();
        this.actionsList = [].concat(...splitedPermissions.map((el: any) => el.actions));
        this.permissionsModules = this.groupPermissionsByModule(splitedPermissions);
        // this.setupChildren();
        this.actionsList.forEach((action: Permission) => {
            this.permissionRequirements[action.name] = this.getActionRequires(action);
            this.permissionConnectedTo[action.name] = [];
        });
        this.onSelectedChange(this.selected);
    }

    public popoverConfirm() {
        if (this.popoverActionName === 'delete') {
            this.deleteWithConnections();
        } else {
            this.selectWithRequired();
        }
        this.showPopover = false;
    }

    public selectWithRequired() {
        const ids = [...this.popoverItems.map((el: Permission) => el.id)];
        if (this.popoverAttachedPermissionId) {
            ids.push(this.popoverAttachedPermissionId);
        }
        this.pushToSelected(...ids.filter((id: number) => !this.selected.includes(id)));
    }

    public deleteWithConnections() {
        const ids = [this.popoverAttachedPermissionId, ...this.popoverItems.map((el: Permission) => el.id)];
        this.selected = this.selected.filter((id: number) => !ids.includes(id));
        this.$forceUpdate();
    }

    public onActionChipClick(action: Permission, permissionBase: any) {
        if (this.showRequirementWarning(action, permissionBase)) {
            this.displayPopover(action);
        } else {
            if (this.selected.includes(action.id)) {
                this.selected = this.selected.filter((id: number) => id !== action.id);
            } else {
                this.selected.push(action.id);
            }
        }
    }

    public onCheckAllClick(permissionModule: any, moduleDisplayName: string) {
        this.popoverActionName = 'add';
        const items: Permission[] = [];
        for (const permissonBase of permissionModule) {
            (permissonBase.actions as Permission[]).forEach((action) => {
                items.push(
                    action,
                    ...this.permissionRequirements[action.name].map((actionId: number) => {
                        return this.actionsList.find((item) => item.id === actionId) as Permission;
                    }),
                );
            });
        }
        _.cloneDeep(items).forEach((el) => {
            if (el.module_parent) {
                items.push(
                    ...this.permissionChildren[el.name].filter((permission: Permission) => permission.isMandatory),
                );
            }
        });
        this.popoverItems = _.uniqBy(items, (el: Permission) => el.id);
        this.$nextTick(() => {
            this.showPopover = true;
        });
    }
    public onUncheckAllClick(permissionModule: any, moduleDisplayName: string) {
        this.popoverActionName = 'delete';
        const items: Permission[] = [];
        for (const permissonBase of permissionModule) {
            (permissonBase.actions as Permission[]).forEach((action) => {
                items.push(
                    ...[
                        action,
                        ...this.permissionConnectedTo[action.name].map((actionId: number) => {
                            return this.actionsList.find((item) => item.id === actionId) as Permission;
                        }),
                    ].filter((el: Permission) => this.selected.includes(el.id)),
                );
                if (action.parent) {
                    items.push(
                        ...this.permissionChildren[action.name].filter((el: Permission) =>
                            this.selected.includes(el.id),
                        ),
                    );
                }
                this.permissionConnectedTo[action.name].forEach((permisssionId: number) => {
                    const permission = this.actionsList.find((el: Permission) => el.id === permisssionId) as Permission;
                    if (permission.module_parent) {
                        items.push(
                            ...this.permissionChildren[permission.name].filter((child: Permission) =>
                                this.selected.includes(child.id),
                            ),
                        );
                    }
                });
            });
        }
        this.popoverItems = _.uniqBy(items, (el: Permission) => el.id);

        this.$nextTick(() => {
            this.showPopover = true;
        });
    }

    public displayPopover(action: Permission) {
        this.popoverAttachedPermissionId = action.id;
        this.popoverItems = [];
        if (this.selected.includes(this.popoverAttachedPermissionId)) {
            this.popoverActionName = 'delete';
            this.setupPopoverItemsForDelete(action);
        } else {
            this.popoverActionName = 'add';
            this.setupPopoverItemsForAddition(action);
        }
        this.popoverItems = _.uniqBy(this.popoverItems, (el) => el.id);
        this.$nextTick(() => {
            this.showPopover = true;
        });
    }

    public showRequirementWarning(action: Permission, permissionModule: { [key: string]: Permission[] }) {
        if (action.module_parent) {
            if (
                this.permissionChildren[action.name].filter(
                    (child: Permission) => this.actionName(child.name) === 'view',
                ).length === 0 &&
                this.permissionChildren[action.name].filter((child: Permission) => this.selected.includes(child.id))
                    .length === 0
            ) {
                return false;
            } else {
                return true;
            }
        }

        return (
            !this.isActionChipDisabled(action) &&
            (this.unselectedRequiredPermissions(action.name).length > 0 ||
                this.permissionConnectedTo[action.name].length > 0)
        );
    }

    public unselectedRequiredPermissions(name: string) {
        const diff = _.difference(this.permissionRequirements[name], this.selected);
        return diff;
    }

    public isActionChipDisabled(action: Permission) {
        if (action.isMandatory) {
            return true;
        }
        if (action.independent) {
            return false;
        }
        if (this.actionName(action.name) === 'view' && !action.optional) {
            return false;
        } else {
            const parentAction = this.getActionParentByDisplayName(action.display_name);

            if (
                action.optional &&
                parentAction &&
                this.selected.includes(parentAction.id) &&
                action.name.endsWith('.view')
            ) {
                if (!parentAction) {
                    return false;
                }
                return !this.selected.includes(parentAction.id);
            }
            const viewAction = this.getViewAction(action.name);
            if (!viewAction) {
                return false;
            }
            return !this.selected.includes(viewAction.id);
        }
    }

    public isAnyInsideModulePermissionSelected(permissionModule: any) {
        for (const permissionBase of permissionModule) {
            if (
                (permissionBase.actions as Permission[]).findIndex((action: Permission) =>
                    this.selected.includes(action.id),
                ) !== -1
            ) {
                return true;
            }
        }
        return false;
    }

    public checkAll() {
        this.selected = this.actionsList.map((el) => el.id);
        this.$forceUpdate();
    }

    public uncheckAll() {
        this.selected = [];
        this.$forceUpdate();
    }
    // tslint:disable-next-line:variable-name
    public getPopoverDisplayName(display_name: string) {
        const nameSplited = display_name.split('-').slice(0, -1);
        return nameSplited.length > 1 ? nameSplited.slice(1).join('-') : nameSplited.join('-');
    }

    private setupPopoverItemsForDelete(action: Permission) {
        this.popoverItems.push(
            ...this.permissionConnectedTo[action.name].map(
                (id: number) => this.actionsList.find((el: Permission) => el.id === id) as Permission,
            ),
        );
        if (!action.optional) {
            this.popoverItems.push(
                ...this.permissionChildren[action.name].filter((el: Permission) => this.selected.includes(el.id)),
            );
        }
        this.permissionConnectedTo[action.name].forEach((permisssionId: number) => {
            const permission = this.actionsList.find((el: Permission) => el.id === permisssionId) as Permission;
            if (permission.parent) {
                this.popoverItems.push(
                    ...this.permissionChildren[permission.name].filter((child: Permission) =>
                        this.selected.includes(child.id),
                    ),
                );
            }
        });
    }
    private setupPopoverItemsForAddition(action: Permission) {
        const items: Permission[] = this.permissionRequirements[action.name].map(
            (id: number) => this.actionsList.find((el: Permission) => el.id === id) as Permission,
        );

        this.popoverItems = items;
        _.cloneDeep(items).forEach((el: Permission) => {
            if (el.parent) {
                this.popoverItems.push(
                    ...this.permissionChildren[el.name].filter((child: Permission) => child.isMandatory),
                );
            }
        });
    }

    private getActionRequires(action: Permission) {
        const { requires, name } = action;

        const required = requires.map(
            (actionName: string) => this.permissions.find((item) => item.name === actionName)?.id,
        );
        const children = this.permissionChildren[name]
            ? this.permissionChildren[name]
                  .filter((el: Permission) => !el.optional && el.id !== action.id)
                  .map((el: Permission) => el.id)
            : [];
        return [...required, ...children];
    }

    private groupPermissionsByModule(splitedPermissions: Permission[]) {
        const permissionsModules = _.groupBy(splitedPermissions, (item: any) => {
            return this.getModuleDisplayName(item.display_name);
        });

        return permissionsModules;
    }

    private getModuleDisplayName(displayName: string) {
        const words = displayName.split('-');
        const word = words[0].trim();

        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    }

    private setupChildren() {
        for (const key of Object.keys(this.permissionChildren)) {
            const action = this.actionsList.find((item: Permission) => item.name === key) as Permission;
            if (action.module_parent) {
                const moduleChildren = this.permissionsModules[this.getModuleDisplayName(action.display_name)]
                    .reduce((a: any, b: any) => {
                        return { ...a, actions: a.actions.concat(b.actions) };
                    })
                    .actions.filter((item: Permission) => item.id !== action.id);
                this.permissionChildren[key].push(...moduleChildren);
            } else if (action.parent) {
                const children = this.permissionsModules[this.getModuleDisplayName(action.display_name)].find(
                    (item: any) => item.name === this.getBaseName(action.name),
                ).actions;
                this.permissionChildren[key].push(...children);
            }
        }
    }

    private splitPermissionsIntoActions() {
        const splitedPermissions: any = [];
        this.permissions.forEach((permission: Permission) => {
            const baseName = permission.name.split('.').slice(0, -1).join('.').trim();
            const displayActionName = this.displayActionName(permission.display_name);
            const actionName = this.actionName(permission.name);
            // tslint:disable-next-line:variable-name
            const display_name = permission.display_name.split('-').slice(0, -1).join(' - ').trim();

            let index = splitedPermissions.findIndex((item: Permission) => item.name === baseName);
            if (index === -1) {
                splitedPermissions.push({ name: baseName, display_name, actions: [] });
                index = splitedPermissions.findIndex((item: Permission) => item.name === baseName);
            }

            const isMandatory =
                !permission.independent &&
                !permission.optional &&
                actionName === 'view' &&
                permission.name.split('.').slice(0, -1).length > 1;

            const action = {
                ...permission,
                displayActionName,
                action_name: actionName,
                isMandatory,
            };

            splitedPermissions[index].actions.push(action);

            if (action.parent) {
                if (!this.permissionChildren[action.name]) {
                    this.permissionChildren[action.name] = [];
                }
            }
        });

        splitedPermissions.map(({ actions, ...element }: any) => {
            return { actions: this.sortActions(actions), ...element };
        });

        return splitedPermissions;
    }

    private getViewAction(name: string) {
        const viewActionName = name.split('.').slice(0, -1).join('.') + '.view';
        return this.actionsList.find((action: Permission) => action.name === viewActionName) as Permission;
    }

    private pushToSelected(...args: number[]) {
        const selectedCopy = _.clone(this.selected);
        selectedCopy.push(...args);
        this.selected = selectedCopy;
    }

    // tslint:disable-next-line:variable-name
    private getActionParentByDisplayName(display_name: string) {
        return this.actionsList.find((action) => action.display_name === display_name.split('-')[0] + '- odczyt');
    }

    private sortActions(actions: Permission[]) {
        return actions.sort((a, b) => {
            const WeightA = this.actionWeight[a.name.split('.').slice(-1)[0] as string] || 5;
            const WeightB = this.actionWeight[b.name.split('.').slice(-1)[0] as string] || 5;
            if (WeightA < WeightB) {
                return -1;
            } else if (WeightB < WeightA) {
                return 1;
            } else {
                return 0;
            }
        });
    }

    // tslint:disable-next-line:variable-name
    private displayActionName(display_name: string) {
        return display_name.split('-').slice(-1).join('').trim();
    }
    private actionName(name: string) {
        return name.split('.').slice(-1)[0];
    }
    private getBaseName(name: string) {
        return name.split('.').slice(0, -1).join('.');
    }

    @Watch('selected', { immediate: true })
    private onSelectedChange(newValue: number[]) {
        this.actionsList.forEach((permission: Permission) => {
            if (newValue.includes(permission.id)) {
                permission.requires.forEach((requirement: string) => {
                    if (!this.permissionConnectedTo[requirement]) {
                        this.permissionConnectedTo[requirement] = [];
                    }
                    this.permissionConnectedTo[requirement].push(permission.id);
                });
            } else {
                permission.requires.forEach((requirement: string) => {
                    if (!this.permissionConnectedTo[requirement]) {
                        this.permissionConnectedTo[requirement] = [];
                    }
                    this.permissionConnectedTo[requirement] = this.permissionConnectedTo[requirement].filter(
                        (id: number) => id !== permission.id,
                    );
                });
            }
        });
    }
}
