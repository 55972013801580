
import {Component} from 'vue-property-decorator';
import ListComponent from '@/shared/components/layout/list/list-component';
import ItemsTable from '@/shared/components/layout/list.vue';
import {ModuleProps} from '@/shared/state/template/module-props';
import {UserInvite} from '@/modules/admin/shared/models/user-invite';
import {UserInviteDataRequest} from '@/modules/admin/shared/requests/user-invite-data-request';
import {userInvitesModule} from '@/modules/admin/shared/state/invites';
import InviteForm from '@/modules/admin/components/invites/form.vue';
import {userInvitesListHeaders} from '@/modules/admin/shared/config';
import TooltipBtn from '@/shared/components/elements/tooltip-btn.vue';
import ActionConfirmDialog from '@/shared/components/dialogs/action-confirm-dialog.vue';
import {UserInviteState} from '@/modules/admin/shared/state/state/invite';
import {inviteStatuses} from '@/shared/config/invites';
import {UsersLimitMetaItem} from '@/modules/admin/shared/models/users-limit-meta-item';

@Component({
    components: {
        ActionConfirmDialog,
        InviteForm,
        ItemsTable,
        TooltipBtn,
    },
})
export default class UserInvitesList extends ListComponent<UserInvite, UserInviteDataRequest> {
    public headers: object[] = userInvitesListHeaders;
    public actionsTypes = userInvitesModule.actionsTypes;
    public mutationTypes = userInvitesModule.mutationsTypes;
    public props: ModuleProps = userInvitesModule.moduleProps;
    public fetchAction = this.actionsTypes.FETCH_DATA;
    public updateAction = this.actionsTypes.UPDATE_ITEM;
    public removeAction = this.actionsTypes.REMOVE_ITEM;
    public store: UserInviteState = this.$store.state.adminState.userInvitesState;

    get meta(): UsersLimitMetaItem {
        return this.store.meta as UsersLimitMetaItem;
    }

    public statusType(statusType: string) {
        return inviteStatuses.find((obj) => obj.type === statusType);
    }

    get resendError() {
        return this.store.resendError;
    }

    get resendErrorMessages() {
        return this.store.resendErrorMessages;
    }

    get resendingItem(): boolean {
        return this.store.resendingItem;
    }

    get resendDialog(): boolean {
        return this.store.resendDialog;
    }

    set resendDialog(value) {
        this.$store.commit(this.mutationTypes.UPDATE_RESEND_DIALOG, value);
    }

    public openResendItemDialog(item: UserInviteDataRequest) {
        this.resendDialog = true;
        this.editedItem = item;
    }

    public closeResend() {
        this.resendDialog = false;
    }

    public resendItem() {
        this.$store.dispatch(this.actionsTypes.RESEND, this.editedItem.id).then((response) => {
            this.resendDialog = !response.success;
            this.fetchData();
        });
    }

    public created() {
        //
    }
}
