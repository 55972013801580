export class UserInviteDataRequest {
    public id: number = 0;
    public email!: string;

    public emails: string[] = [];
    // tslint:disable-next-line:variable-name
    public role_ids: number[] = [];
    // tslint:disable-next-line:variable-name
    public is_admin: boolean = false;
}
