
import { Component, Inject } from 'vue-property-decorator';
import ListComponent from '@/shared/components/layout/list-component';
import ActionConfirmDialog from '@/shared/components/dialogs/action-confirm-dialog.vue';
import { usersListHeaders } from '@/modules/admin/shared/config';
import { ErrorBoundary } from '@/shared/components';
import { actionsTypes } from '@/modules/admin/shared/state';
import ListToolbar from '@/shared/components/layout/list/list-toolbar.vue';
import { usersListFilters } from '@/modules/admin/shared/config/filters';
import FilterBar from '@/shared/components/layout/list/filter-bar.vue';
import { User } from '@/modules/admin/shared/models/user';
import { Role } from '@/modules/admin/shared/models/role';
import UserCard from './user-card.vue';
import InviteForm from '../invites/form.vue';
import {userInvitesModule} from '@/modules/admin/shared/state/invites';
import {UserInviteDataRequest} from '@/modules/admin/shared/requests/user-invite-data-request';
import { callSuper } from '@/shared/helpers';
import { UsersLimitMetaItem } from '../../shared/models/users-limit-meta-item';

@Component({
    components: {
        ErrorBoundary,
        ActionConfirmDialog,
        ListToolbar,
        FilterBar,
        UserCard,
        InviteForm,
    },
})
export default class UsersList extends ListComponent<User> {
  @Inject() public readonly permissionCheck!: (...permissions: string[]) => boolean;

    public headers: object[] = usersListHeaders;
    public store = this.$store.state.adminState;
    public fetchAction = actionsTypes.FETCH_USER_DATA;
    public removeAction = actionsTypes.REMOVE_USER;
    public restoreAction = actionsTypes.RESTORE_USER;
    public newUserDialog = false;
    public userInvitesMutations = userInvitesModule.mutationsTypes;
    public invitesState = this.$store.state.adminState.userInvitesState;

    public created() {
        this.fetchData();
    }

    public rowClasses(item: User): string[] {
        if (item.trashed) {
            return ['table-row-disabled'];
        }

        return [];
    }

    public editItem(item: User) {
        callSuper(this, 'editItem', item);
        this.editedItem.avatar_photo_id = this.editedItem.avatar_photo;
        this.editedItem.roles = this.editedItem.roles.map((role: Role) => {
            return role.id;
        });
    }

    get users() {
        return this.store.users;
    }

    public inviteSaved() {
        this.invitesFormDialog = false;

        setTimeout(() => {
            this.invitesEditedItem = {};
        }, 200);
    }

    public closeInviteForm() {
        this.invitesFormDialog = false;

        setTimeout(() => {
            this.invitesEditedItem = {};
        }, 200);
    }

    get canCreate() {
        let message = '';
        if (this.meta && this.meta.max_users > 0) {
            if (this.meta.active_users >= this.meta.max_users) {
                message = 'Osiągnięto limit użytkowników';
            } else if (this.meta.active_users + this.meta.active_invites >= this.meta.max_users) {
                message = 'Osiągnięto limit - ' + this.meta.active_invites
                    + ' zaproszenia oczekują na akceptację';
            }
        }
        return message;
    }

    get meta(): UsersLimitMetaItem {
        return this.store.meta as UsersLimitMetaItem;
    }

    get invitesFormDialog() {
        return this.invitesState.formDialog;
    }

    set invitesFormDialog(value) {
        this.$store.commit(this.userInvitesMutations.UPDATE_FORM_DIALOG, value);
    }

    get invitesEditedItem() {
        return this.invitesState.editedItem;
    }

    set invitesEditedItem(value) {
        this.$store.commit(this.userInvitesMutations.UPDATE_EDITED, value);
    }

    public openInviteForm() {
        this.newUserDialog = false;
        this.invitesFormDialog = true;
        this.invitesEditedItem = new UserInviteDataRequest();
    }

    get maxUsers() {
        const maxUsers =
            this.store && this.store.meta && (this.store.meta.max_users || Number(this.store.meta.max_users) === 0)
                ? Number(this.store.meta.max_users)
                : null;

        if (maxUsers === 0) {
            return 'Nieograniczony';
        }

        if (maxUsers && maxUsers > 0) {
            return maxUsers;
        }

        return '-';
    }

    get activeUsers() {
        return this.store && this.store.meta && this.store.meta.active_users
            ? Number(this.store.meta.active_users)
            : '-';
    }

    get percentageOfUsedLimit() {
        const maxUsers = Number(this.maxUsers);
        const activeUsers = Number(this.activeUsers);
        if (!isNaN(maxUsers) && !isNaN(activeUsers)) {
            return (activeUsers / maxUsers) * 100;
        }
        return NaN;
    }

    get limitInfo() {
        const { maxUsers, percentageOfUsedLimit, activeUsers } = this;
        if (activeUsers && maxUsers && maxUsers === 'Nieograniczony') {
            return `Limit użytkowników: ${maxUsers}`;
        }

        if (
            activeUsers &&
            maxUsers &&
            typeof maxUsers === 'number' &&
            typeof activeUsers === 'number' &&
            !isNaN(maxUsers) &&
            !isNaN(maxUsers)
        ) {
            return `Limit użytkowników: ${activeUsers} z ${maxUsers}`;
        }

        return '';
    }

    get loggedUserId() {
        return this.$store.state.authState.user ? this.$store.state.authState.user.id : 0;
    }

    get filterConfig() {
        return usersListFilters;
    }

    public isPermitted(actionName: string) {
        return this.permissionCheck(`admin.users.${actionName}`);
    }
}
