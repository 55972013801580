
import {Component, Prop, VModel, Vue, Watch} from 'vue-property-decorator';
import {getSummaryData, postSubscription} from '@/modules/admin/shared/services';
import {actionsTypes} from '@/modules/admin/shared/state';
import {parsePrice} from '@/shared/helpers';
import AddressField from '@/shared/components/elements/address.vue';
import SummaryOrderItem from '@/modules/admin/components/subscriptions/form/summary/order-item.vue';
import {OrderItem} from '@/modules/admin/shared/models/orders/order-item';
import {SummaryPayload} from '@/modules/admin/shared/models/subscriptions/summary-payload';
import {parsePlanPeriod} from '@/modules/admin/shared/helpers/plans';

@Component({
    components: {SummaryOrderItem, AddressField}
})
export default class SummaryComponent extends Vue {
    @VModel({type: Object}) public summaryData!: SummaryPayload;
    @Prop(Number) public step!: number;
    @Prop(Number) public subscriptionId!: number;

    public loadingPay: boolean = false;
    public parsePrice = parsePrice;
    public error: string = '';
    public errorMessages: string = '';
    public loadingSummary: boolean = false;

    public get planOrder(): OrderItem | null {
        if (this.summaryData) {
            const plan = this.summaryData.plan;

            return {
                name: `${plan.name} (${parsePlanPeriod(plan.period)})`,
                amount: plan.price,
                sellable_type: 'subscription',
            } as OrderItem;
        }
        return null;
    }

    public get currentStep() {
        return this.step;
    }

    public set currentStep(value) {
        this.$emit('update:fromSummary', true);
        this.$emit('update:step', value);
    }

    public async getSummary() {
        this.loadingSummary = true;
        getSummaryData(this.subscriptionId)
            .then((response: SummaryPayload) => {
                if (response) {
                    this.summaryData = response;
                }
                this.loadingSummary = false;
            })
            .catch((error) => {
                if (error.errors.plan_active) {
                    this.$emit('update:step', 1);
                } else if (error.errors.billing_address) {
                    this.$emit('update:step', 2);
                } else if (error.errors.payment_method_active) {
                    this.$emit('update:step', 3);
                } else {
                    this.$store.dispatch(actionsTypes.SNACKBAR_TRIGGER, {
                        type: 'error',
                        text: 'Coś poszło nie tak',
                    });
                }
            });
    }

    public postSubscription() {
        this.loadingPay = true;
        postSubscription(this.subscriptionId)
            .then(
                ({status, redirect_url}: any) => {
                    if (status) {
                        window.location.replace(redirect_url);
                    } else {
                        this.loadingPay = false;
                    }
                },
            );
    }

    @Watch('step', {immediate: true})
    public onStepChange(newVal: number) {
        if (this.step === 4) {
            this.getSummary();
        }
    }
}
