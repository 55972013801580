import { render, staticRenderFns } from "./list.vue?vue&type=template&id=54d5ff55&"
import script from "./list.vue?vue&type=script&lang=ts&"
export * from "./list.vue?vue&type=script&lang=ts&"
import style0 from "./list.vue?vue&type=style&index=0&id=54d5ff55&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VAlert,VCard,VContainer,VDataTable,VDialog,VIcon,VTextField})
