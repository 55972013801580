import {ListFilter} from '@/shared/types';

export const usersListFilters: ListFilter[] = [
    {
        name: 'withTrashed',
        label: 'Usunięte konta',
        type: 'switch',
        description: 'Wyświetl także usunięte konta użytkowników.',
    },
];
