
import {Component, Prop, Vue} from 'vue-property-decorator';
import PlansComponent from '@/modules/admin/components/subscriptions/form/plans.vue';
import InvoiceData from '@/modules/admin/components/subscriptions/form/invoice-data.vue';
import PaymentMethods from '@/modules/admin/components/subscriptions/form/payment-methods.vue';
import SummaryComponent from '@/modules/admin/components/subscriptions/form/summary.vue';
import ErrorBoundary from '@/shared/components/error-boundary/error-boundary.vue';
import {SummaryPayload} from '@/modules/admin/shared/models/subscriptions/summary-payload';

@Component({
    components: {
        ErrorBoundary,
        SummaryComponent,
        PlansComponent,
        InvoiceData,
        PaymentMethods,
    },
})
export default class SubscriptionForm extends Vue {
    @Prop(Number) public subscriptionId!: number;
    public fromSummary: boolean  = false;
    public summaryData: SummaryPayload = {} as SummaryPayload;
    public currentStep: number = 0;

    public mounted() {
        this.currentStep = this.subscriptionId ? 4 : 1;
    }

    public setCurrentStep() {
        if (this.fromSummary) {
            this.currentStep = 4;
        } else {
            this.currentStep++;
        }
    }
}
